import React from 'react';
import axios from 'axios';
import http from './modules/api';
import { setCookie } from "./modules/utils/CookieUtil";
import { Link } from 'react-router-dom';
import '../css/style.css';
import * as navigator from './modules/navigator';
import MetaTags from 'react-meta-tags';
import Slider from "react-slick";
import logo4 from "../resources/img/icn_logo_v2.svg";
import logo5 from "../resources/img/logo_v2_white.svg";
import login_rouding_background_1 from "../resources/img/login_rouding_background_1.png";
import login_rouding_background_2 from "../resources/img/login_rouding_background_2_1.png";
// import Bookmark from 'react-bookmark';
import Inko from 'inko';
let inko = new Inko();
export class Login extends React.Component {
	
	state = {
			username: '',
			password: '',
	}
	
	handleText = (e, key) => {
		// computed property
		if(key == "password") {
			// console.log(inko.ko2en(e.target.value));
			this.setState({[key]: inko.ko2en(e.target.value)});
		} else {
			// console.log(e.target.value);
			this.setState({[key]: e.target.value});
		}
	}
	
	handleKeyPress = (e) => {
	    if (e.key === "Enter") {
	    	this.fn_loginProcess();
	    }
	}

	onClickLoginButton = () => {
		this.fn_loginProcess();
	};

	onClickDemoButton = () => {
		sessionStorage.setItem("user_id", "DemoId");
		sessionStorage.setItem("userName","DemoUser");
		sessionStorage.setItem("roleType","none");
		sessionStorage.setItem("redirectUrl","/demo/trend/current");
		sessionStorage.setItem("solution_type_cd","");
		setCookie("user_id", "DemoId");
		setCookie("solution_type_cd", "");
	};
	
	fn_loginProcess() {
		const sendForm = {...this.state};
		http.post("/loginProcess", null, {params:sendForm, Pragma: "no-cache"})
			 .then(
					 resultData => {
						 var resUrl = resultData.request.responseURL;
						 //console.log(JSON.stringify(resultData));
						 //console.log("resultData.request.responseURL : "+(resultData.request.responseURL));
						 //console.log(resultData.headers['location']);
						 //console.log(resultData.response.location);
						 //alert("######### resUrl : " + resUrl + "    "+ resultData.request.config.url);
						 
						 if (resultData.request.responseURL == null) {
							resUrl = "/";
						 } else if (resultData.request.responseURL.indexOf("error") > 0) {
	                        alert("가입하지 않은 아이디이거나, 잘못된 비밀번호입니다.");
	                        return false;
	                        // window.location.href = "/login"
						 }

						 //let r = (Math.random() + 1).toString(36).substring(7);
						 this.fn_loginLog();
						 window.location.href = resUrl;	// + "?v=" + r;
						 sessionStorage.setItem("isLoadBannerInstallCntForMenu", true); // 메뉴용 설치 여부 적용 상태값

						 // 로그인전 접근 페이지가 아래의 경로일때만
//						 if (resUrl.indexOf("/shop/pwdMod") > 0) {
//							 //window.location.href = resUrl;
//						 } else {
//							 //window.location.href = "/";
//						 }						 
					 })
			 .catch(
					 //error => { alert("인증되지 않은 사용자 입니다."); console.log(error); }
			 );
	}

	fn_loginLog() {

		const browser = navigator.detectBroeser();
		const os = navigator.detectOS();
		const device = navigator.detectDevice();

		http.get("/rest/common/loginLog", {
			params:{
				browser: browser,
				os: os,
				device: device
			}
		})
		.then(
			resultData => {
				console.log(resultData.data);
		})
		.catch(
			error => {
				console.log(error);
			}
		);
	}

	componentDidMount() {
//		var referrer = sessionStorage.getItem("referrer");
//		if (referrer != null) {
//			//console.log("referrer : " + referrer);
//			sessionStorage.setItem("referrer", document.referrer);
//		}
		// axios.get("/logout")
		// .then(res => {
		// 	// alert("세션이 끊어졌습니다.")
		// })
		// .catch(error => {
		// 	// console.log (error);
		//  });
	}

	render() {
		return (
			<>
				{/* <div id='non-page-wrapper mobile-non-page-wrapper' style={{margin:0}}> */}
					{/* <Bookmark className="coolClass" href="/" title="ROBOMD" /> */}
					<div className="mobile_margin_top_0 middle-box middle-box-mobile text-center" style={{display:"flex", maxWidth:"100%", paddingTop:0, height:"100vh"}}>
						<section className="login-section" style={{display: "flex", width:"100%", height:"auto", margin:"auto 0", background:"white"}}>
							{/* <h3 className="m-t-none m-b">로그인</h3> */}
							<div className="container">
								<div className="form-inline">
									<div  style={{display: "flex", width:"100%", textAlign:"left", color:"#333333", fontWeight:"bold", fontSize:"25px", marginBottom:"10vh", justifyContent:"space-between"}}>
										<div>
										환영합니다!<br/>로그인 해 주세요.
										</div>
										<div style={{display: "flex", minWidth: "59px", marginLeft:"10px", paddingTop: "5px"}}>
											<Link to={`/common/registUser`} style={{alignSelf:"flex-start", fontSize:"17px", color: "#00e2e6"}}>회원가입</Link>
										</div>
									</div>
									<label style={{color:"#333333", fontWeight:"bold", marginBottom:"27px"}}>
										아이디
									</label>
									<div  style={{display: "flex", width:"100%", height:"124px"}}>
										<input className="form-control" style={{width:"100%", background:"#ffffff"}} type="text" placeholder="아이디를 입력해 주세요." id="username" value={this.state.username} 
											onChange={(e) => this.handleText(e, 'username')}
											onKeyPress={this.handleKeyPress}
										/>
									</div>
									<label style={{color:"#333333", fontWeight:"bold", marginBottom:"27px"}}>
										비밀번호
									</label>
									<div  style={{display: "flex", width:"100%", height:"124px"}}>
										<input className="form-control" style={{width:"100%", background:"#ffffff"}} type="password" pattern="[A-Za-z0-9]*" placeholder="비밀번호를 입력해 주세요." id="password" value={this.state.password} 
											onChange={(e) => this.handleText(e, 'password')}
											onKeyPress={this.handleKeyPress}
										/>
									</div>
									<div className="mobile-flex-wrap-align-right" style={{display: "flex", width:"100%", justifyContent:"space-between"}}>
										<div className="find_btn_box m-t-xs mobile_margin_bottom_30px" style={{width:"auto", display:"flex", flexWrap: "nowrap", margin:"auto 0", alignContent:"middle", fontSize:"15px", lineHeight:"20px"}}>
											{/* <Link className="btn find_btn" to={`/common/registUser`}>회원가입</Link> */}
											<div style={{color:"#333333"}}>계정을 잊으셨나요?&nbsp;&nbsp;</div>
											<Link to={`/common/id/search`} style={{color:"#00e2e6"}}>아이디&nbsp;·&nbsp;</Link>
											<Link to={`/common/pwd/search`} style={{color:"#00e2e6"}}>비밀번호찾기</Link>
										</div>
										<div className="mobile_margin_bottom_30px mobile_margin_left_20px" style={{width:"200px", height:"60px", margon:"auto 0", fontSize:"15px"}}>
											<button className="btn-white main_login" style={{height:"100%", color:"white", background:"#00e2e6", borderRadius: "13px", lineHeight:"20px" , border:0}} onClick={this.onClickLoginButton}>로그인 하기</button>
										</div>
									</div>
									<div className="cafe24_login_box">
										<div className="cafe24_start_div">
											<div className="icon_cafe24_start_logo"></div>
											<div>로 시작하기</div>
										</div>
										<div className="cafe24_service_box">
											<a id="01001" className="cafe24_service_button" href="https://store.cafe24.com/kr/apps/3652">배너</a>
											<a id="01002" className="cafe24_service_button" href="https://store.cafe24.com/kr/apps/14376">진열</a>
											<a id="01009" className="cafe24_service_button" href="https://store.cafe24.com/kr/apps/22839">타겟뷰</a>
										</div>
									</div>
									<div className="find_btn_box_parent">
										
									</div>
								</div>
							</div>
						</section>
						{/* <div style={{marginTop:"15px"}}>
						iclave © 2021
						</div>

						<div style={{marginTop:"30px"}} className="btn-demo-area">
							<a href="https://s3.ap-northeast-2.amazonaws.com/otdeal.file/ROBOTMD_%E1%84%8C%E1%85%A1%E1%84%83%E1%85%A9%E1%86%BC%E1%84%87%E1%85%A2%E1%84%82%E1%85%A5_%E1%84%89%E1%85%A1%E1%84%8B%E1%85%AD%E1%86%BC%E1%84%89%E1%85%A5%E1%86%AF%E1%84%86%E1%85%A7%E1%86%BC%E1%84%89%E1%85%A5.pdf" target="blank">
								<div className="btn-demo">자동기획전 사용설명서</div>
							</a>
						</div> */}
					</div>
				{/* </div>	 */}
				{/* <footer className="flexBox" style={{borderTop: "1px solid  #c3c3c3", position:"absolute", bottom:"0", backgroundColor:"white", width: "100%", padding:"20px 0 20px 0", color:"#3c3c3c", fontSize:"14px"}}>
					<div className="footer-first-child" style={{width:"32%", paddingLeft:"3.5%"}}>
						<div style={{display: "table", width:"auto"}}>
							<div style={{display: "table-cell", verticalAlign: "middle"}}>
								(주)아이클레이브 대표이사 : 최윤내<br/>
								<br/>
								사업자등록번호 : 381-81-00136<br/>
								통신판매번호 : 2021-서울강남-03381<br/>
								사업장 소재지 : 서울특별시 강남구 테헤란로2길, 13층 1308호(역삼동, 패스트파이브빌딩)<br/>
								이메일 : robomd@robomd.io
							</div>
						</div>
					</div>
					<div className="footer-second-child" style={{width:"32%", borderLeft:"1px solid  #c3c3c3", textAlign:"center"}}>
						<div className="flexBox" style={{width:"100%", height:"50%"}}>
							<div style={{width:"33%",height:"100%"}}>
								<div style={{display: "table", width:"auto", height:"100%", margin:"auto"}}>
									<div style={{display: "table-cell", verticalAlign: "middle"}}>
										<a href="https://iclave.co.kr/contact.html" target="blank">문의 하기</a>
									</div>
								</div>
							</div>
							<div style={{width:"33%",height:"100%", borderLeft:"1px solid  #c3c3c3"}}>
								<div style={{display: "table", width:"auto", height:"100%", margin:"auto"}}>
									<div style={{display: "table-cell", verticalAlign: "middle"}}>
										<a href="https://iclave.co.kr" target="blank">회사 사이트</a>
									</div>
								</div>	
							</div>
							<div style={{width:"33%",height:"100%", borderLeft:"1px solid  #c3c3c3"}}>
								<div style={{display: "table", width:"auto", height:"100%", margin:"auto"}}>
									<div style={{display: "table-cell", verticalAlign: "middle"}}>
										<a href="/common/FormViewer/9001" target="blank">이용약관</a>
									</div>
								</div>	
							</div>
						</div>
						<div className="flexBox" style={{width:"100%", height:"55%", paddingTop: "10px", margin: "10px 0 30px 0", borderTop:"1px solid  #c3c3c3"}}>
							<div style={{width:"33%",height:"100%"}}>
								<div style={{display: "table", width:"auto", height:"100%", margin:"auto"}}>
									<div style={{display: "table-cell", verticalAlign: "middle"}}>
										<a href="https://www.instagram.com/otdeal_official" target="blank">Instagram</a>
									</div>
								</div>	
							</div>
							<div style={{width:"33%",height:"100%", borderLeft:"1px solid  #c3c3c3"}}>
								<div style={{display: "table", width:"auto", height:"100%", margin:"auto"}}>
									<div style={{display: "table-cell", verticalAlign: "middle"}}>
										<a href="https://www.facebook.com/otdeal" target="blank">Facebook</a>
									</div>
								</div>
							</div>
							<div style={{width:"33%",height:"100%", borderLeft:"1px solid  #c3c3c3"}}>
								<div style={{display: "table", width:"auto", height:"100%", margin:"auto"}}>
									<div style={{display: "table-cell", verticalAlign: "middle"}}>
										<a href="/common/FormViewer/9002" target="blank">개인정보처리방침</a>
									</div>
								</div>
							</div>
						</div>
					</div>		
					<div className="footer-third-child" style={{width:"32%", borderLeft:"1px solid  #c3c3c3"}}>
					<div style={{display: "table", width:"auto", height:"100%", margin:"auto"}}>
						<div style={{display: "table-cell", verticalAlign: "middle"}}>
							<h1><b>고객센터 : 1599-3518</b></h1><br/>

							오전 10:00 ~ 오후 7:00 lunch 13:00 ~ 14:00 토요일/공휴일 휴무<br/>
						</div>
					</div>
					</div>
				</footer>	 */}

				{/* <footer className="mobile-bottom-11"  style={{padding:"0 2.5%", position:"absolute", bottom:"82px", width:"100%"}}>
					<div style={{display:"flex", flexWrap:"wrap", justifyContent:"space-between"}}>
						<div style={{display:"flex", marginBottom:"20px"}}>
							<div>
								<div style={{fontSize:"13px", fontWeight:"bold", marginBottom:"15px"}}>
									정책
								</div>
								<div style={{fontSize:"12px"}}>
									<a href="/common/FormViewer/9001" target="blank">이용약관</a>
								</div>
								<div style={{fontSize:"12px", color: "#10CFC9"}}>
									<a href="/common/FormViewer/9002" target="blank">개인정보 처리방침</a>
								</div>
							</div>
							<div style={{marginLeft:"31px"}}>
								<div style={{fontSize:"13px", fontWeight:"bold", marginBottom:"15px"}}>
									회사
								</div>
								<div style={{fontSize:"12px"}}>
									<a href="https://iclave.co.kr" target="blank">회사소개</a>
								</div>
							</div>
						</div>
						<div style={{textAlign:"right", marginBottom:"20px"}}>
							<div className="mobile_width_100" style={{display:"flex", flexWrap:"wrap", flexDirection:"column"}}>
								<div className="mobile_align_left" style={{fontSize:"13px", fontWeight:"bold", marginBottom:"15px"}}>
									아이클레이브
								</div>
								<div className="mobile_align_left" style={{fontSize:"12px"}}>
									서울특별시 강남구 테헤란로2길 27 패스트파이브(강남 5호점) 빌딩 1308호
								</div>
							</div>
							<div style={{display:"flex", flexWrap:"wrap", fontSize:"12px"}}>
								<div className="mobile_width_100" style={{display:"flex"}}>
									<div>
									Tel
									</div>
									<div style={{fontWeight: "bold", fontSize:"12px", marginLeft:"6px"}}>
										<a href="tel:02-6383-7976" target="blank">02-6383-7976</a>
									</div>
									<div style={{marginLeft:"15px"}}>
									Email
									</div>
									<div style={{fontWeight: "bold", fontSize:"12px", marginLeft:"6px"}}>
										<a href="mailto:robomd@robomd.io" target="blank">robomd@robomd.io</a>
									</div>
								</div>
								<div className="mobile_width_100" style={{display:"flex"}}>
									<div className="mobile_margin_left_0" style={{marginLeft:"15px"}}>
									대표이사
									</div>
									<div style={{fontWeight: "bold", fontSize:"12px", marginLeft:"6px"}}>
									최윤내
									</div>
									<div style={{marginLeft:"15px"}}>
									사업자등록번호
									</div>
									<div style={{fontWeight: "bold", fontSize:"12px", marginLeft:"6px"}}>
									381-81-00136
									</div>
								</div>
							</div>
						</div>
					</div>
				</footer> */}
			</>
			
		);
	}
	
}