import React, {useState, useEffect, useCallback, useRef} from 'react'
import { useLocation, useHistory} from 'react-router-dom'
import SelectBoxV2 from '../modules/common/SelectBoxV2'
import RadioButton from '../modules/common/RadioButton'
import { APIs } from '../modules/apiClient'
import round_loading_bar from '../../resources/img/round_loading_bar.gif'
import fix_icon from '../../resources/img/fix_icon.svg';
import $ from 'jquery';
import LazyLoad from 'react-lazyload';

/**
 * 상품자동진열 - 쇼핑몰 통계 페이지 (진열별 통계)
 */
let letCafe24CategoryNo = "";
let letCafe24CategoryList = [];
const RpProductListStatistics = (props) => {
    const location = useLocation()
    const history = useHistory()
    const [iframePageIdList, setIframePageIdList] = useState([]);
    const [iframePageId, setIframePageId] = useState("robomd_cafe24_main_page");
    const [mainCategoryList, setMainCategoryList] = useState([])
    const [mainDisplayGroup, setMainDisplayGroup] = useState(null)
    const [productList, setProductList] = useState([])
    const [analysisStandardValue, setAnalysisStandardValue] = useState("4")
    const [cafe24CategoryList, setCafe24CategoryList] = useState([])
    const [cafe24CategoryNo, setCafe24CategoryNo] = useState("")
    const [cafe24CategoryNoList, setCafe24CategoryNoList] = useState(new Array(5).fill(""))
    const [cafe24CategoryDisplayGroup, setCafe24CategoryDisplayGroup] = useState(1)
    const [showLoadingBar, setShowLoadingBar] = useState(true)
    const [highlightType, setHighlightType] = useState("")
    const [categoryDepth, setCategoryDepth] = useState(2)

    const highlightConnect = useRef();

    const eventItemAnalysisStandardList = [
        { value: "1/D", title: "최근 1일" },
        { value: "2/D", title: "최근 2일" },
        { value: "3/D", title: "최근 3일" },
        { value: "4/D", title: "최근 4일" },
        { value: "5/D", title: "최근 5일" },
        { value: 1, title: "최근 1주" },
        { value: 2, title: "최근 2주" },
        { value: 3, title: "최근 3주" },
        { value: 4, title: "최근 4주" },
    ];
    const cafe24CategoryDisplayGroupList = [{value:1, title:"일반 상품"},{value:2, title:"추천 상품"},{value:3, title:"신상품"}]

    /** 무료 체험 기간 조회 : 무료체험 기간이 끝났다면 위에 링크를 쳐서 들어오는 것을 막는다 */
    const loadFreeDatePeriod = async () => {
        await APIs.rp.getCheckingFreeDatePeriod()
            .then(res => {
                if (res.data != null && res.data != '') {
                    // console.log("this.props.history.action", this.props.history.action);
                    if(res.data["is_finish"] != 0){
                        alert("사용 기간이 종료 되었습니다");
                        // this.props.history.replace({ pathname: `/shop/rp/event/list` })
                        if (history.action !== 'POP') {
                            history.goBack();
                        } else {
                            history.replace({ pathname: `/shop/rp/main` })
                        }
                    }
                }
            })
            .catch(error => {});
    }

    /** Iframe 설정값 불러오기 */
    const loadUpperCodeListIframeList = async () => {
        try {
            const res = await APIs.rp.getSettingsByUpperCode("16000");
            if (res.data != null && res.data !== '') {
                let iframe_page_id_list = res.data;
                if (sessionStorage.getItem("user_id") !== "test_rp" && sessionStorage.getItem("user_id") !== "marketb") {
                    iframe_page_id_list = iframe_page_id_list.filter(item => item.code_cd !== "16002" && item.val2 === "cafe24");
                }
                setIframePageIdList(iframe_page_id_list);
            }
        } catch (err) {
            console.error("Error loading iframe list: ", err);
        }
    };
    
    /** 왼쪽 영역 값 불러오기 (진열 위치) */
    const loadUpperCodeListCategoryList = async () => {
        try {
            if (mainCategoryList != null && mainCategoryList.length > 0) {
                setMainDisplayGroup(mainCategoryList[0]["display_group"]);
                return;
            }
            const res = await APIs.rp.getMainCategory();
            if (res.data.code == 200) {
                setMainCategoryList(res.data["main_category_list"]);
                setMainDisplayGroup(res.data["main_category_list"][0]["display_group"]);
            }
        } catch (err) {
            console.error("Error loading category list: ", err);
        }
    };
    
    /** 카테고리 진열 - 상품분류 불러오기 */
    const loadCafe24Category = async (parentCategory, depth) => {
        try {
            const res = await APIs.rp.getCafe24Category(depth, parentCategory);
            if (res.data.code == "200") {
                const productCategoryList = res.data["product_category_list"];
                const newCafe24CategoryNoList = [...cafe24CategoryNoList];
                if (depth == 1) {
                    newCafe24CategoryNoList[depth-1] = productCategoryList[0]["category_no"];
                } 
                for (let idx = depth == 1 ? 2 : (depth-1); idx <= 4; idx++) {
                    newCafe24CategoryNoList[idx] = "";
                }
                setCafe24CategoryNoList(newCafe24CategoryNoList);
                if (depth >= 2) {
                    if (productCategoryList.length > 0) {
                        productCategoryList.splice(0,0,{category_name : "전체상품", category_no : "전체상품"});
                    }
                } else {
                    setCafe24CategoryNo(productCategoryList[0]["category_no"]);
                }
                const newCafe24CategoryList = [...letCafe24CategoryList];
                newCafe24CategoryList[depth - 1] = productCategoryList;
                for (let idx = depth; idx <= 5; idx++) {
                    newCafe24CategoryList[idx] = [];
                }
                letCafe24CategoryList = newCafe24CategoryList;
                setCafe24CategoryList(newCafe24CategoryList);
            }
        } catch (err) {
            console.error("Error loading cafe24 category: ", err);
        }
    };
    

    /** 메인진열 - 카페24에서 메인진열을 불러오는 함수 */
    const loadCafe24MainProductList = useCallback(async () => {
        if (!mainDisplayGroup) return;
        setShowLoadingBar(true);
        try {
            const res = await APIs.rp.getCafe24MainProductList(mainDisplayGroup);
            if (res.data.code == "200") {
                setProductList(res.data.main_product_list.map((obj, idx) =>
                    obj.fixed_sort ? { ...obj, sequence_no: idx + 1 } : obj
                ));
            }
        } catch (err) {
            console.log(err);
        }
        setShowLoadingBar(false);
    }, [mainDisplayGroup]);
    
    /** 카테고리 진열 - 카페24에서 카테고리 진열을 불러오는 함수 */
    const loadCafe24CategoryProductList = useCallback(async () => {
        setShowLoadingBar(true)
        await APIs.rp.getCafe24CategoryProductList(cafe24CategoryNo, cafe24CategoryDisplayGroup)
        .then(res=> {
            setProductList(res.data["category_product_list"])
            setShowLoadingBar(false)
        })
    }, [cafe24CategoryNo, cafe24CategoryDisplayGroup, cafe24CategoryNoList])

    /** 통계수 분석기간 */
    const reloadProductByAnalysisStandard = useCallback(async () => {
        const product_nos = productList.map(f=> f.product_no)
        try {
            const res = await APIs.rp.getProductsByAnalysisStandard(product_nos, analysisStandardValue, 0, product_nos.length)
            const list = res.data.list;
                let copy_product_list = JSON.parse(JSON.stringify(productList));
                for (let idx1 in productList) {
                    for (let idx2 in list) {
                        if (productList[idx1].product_no == list[idx2].product_no) {
                            /* analysis_standard_value에 의해 조회된 값으로 대체 */
                            copy_product_list[idx1].hit_cnt = list[idx2].hit_cnt
                            copy_product_list[idx1].cart_cnt = list[idx2].cart_cnt
                            copy_product_list[idx1].wish_cnt = list[idx2].wish_cnt
                            copy_product_list[idx1].review_point = list[idx2].review_point
                            copy_product_list[idx1].review_cnt = list[idx2].review_cnt
                            copy_product_list[idx1].order_cnt = list[idx2].order_cnt
                            copy_product_list[idx1].order_price = list[idx2].order_price
                        }
                    }
                }

            setProductList(copy_product_list)
        } catch (error) {
            console.log(error)
        }
    }, [analysisStandardValue]);
    

    /** 메인진열, 카테고리진열 확인 */
    const getCurrentPath = () => {
        return location.pathname.split('/')[location.pathname.split('/').length - 1]
    }

    /** 메인 테이블이 로드 될떄 드래그 스크롤을 위한 max_height 설정 */
    const onLoadMainTable = () => {
        let window_height = $(window).height();
        // window 높이에 따라서 메인테이블의 max-height가 달라지도록 적용(드래그 스크롤을 위한 작업)
        $("#list_admin_main_board").css({"max-height":window_height-330});
        $("#main_table_tbody").css({"max-height":window_height-400});
    }

    /** 카페24 카테고리진열 카테고리 변경 */
    const onChangeCafe24Category = (name, value, id) => {
        const depth = Number(id.split('_')[2]) + 2;
        setCategoryDepth(depth);
        let newCafe24CategoryNoList = [...cafe24CategoryNoList];
        
        if (["전체상품", ""].includes(value)) {
            if (depth > 2) {
                for (let idx = depth-2; idx <= 3; idx++) {
                    newCafe24CategoryNoList[idx] = "";
                }
                setCafe24CategoryNo(newCafe24CategoryNoList[depth - 3]);
                const newCafe24CategoryList = [...cafe24CategoryList];
                for (let idx = depth-1; idx <= 3; idx++) {
                    newCafe24CategoryList[idx] = [];
                }
                setCafe24CategoryList(newCafe24CategoryList);
            } else {
                setCafe24CategoryNo("");
                newCafe24CategoryNoList[depth - 2] = "";
            }
        } else {
            setCafe24CategoryNo(value);
            newCafe24CategoryNoList[depth - 2] = value;
        }
        setCafe24CategoryNoList(newCafe24CategoryNoList);
    };

    useEffect(() => {
        if ((letCafe24CategoryNo == cafe24CategoryNo)) {
            return;
        }
        if (categoryDepth !== 5) {
            let newCafe24CategoryList = [...cafe24CategoryList];
            if (!["전체상품", ""].includes(cafe24CategoryNo)) {
                loadCafe24Category(cafe24CategoryNo, categoryDepth);
            } else {
                if (newCafe24CategoryList) {
                    newCafe24CategoryList[categoryDepth - 1] = [];
                    setCafe24CategoryList(newCafe24CategoryList);
                }
            }
        }
        letCafe24CategoryNo = cafe24CategoryNo
    }, [cafe24CategoryNo, cafe24CategoryNoList, categoryDepth]);
    
    /** 메인진열 <-> 카테고리 진열 페이지 이동 */
    const reDirection = (to) => {
        window.location.href = `/shop/rp/event/product/statistics/${to}`;
    }

    useEffect(() => {
        if(props.location.state && props.location.state.highlightType){
            setHighlightType(props.location.state.highlightType)
        }

        const fetchData = async () => {
            try {
                await loadFreeDatePeriod();
                await loadUpperCodeListIframeList();
                if (iframePageId === "robomd_cafe24_main_page") await loadUpperCodeListCategoryList();
                else await loadCafe24Category(1, 1);
            } catch (error) {
                console.error("Error in fetchData: ", error);
            }
        };
        fetchData();
    
        // if (getCurrentPath() === "main") setIframePageId("robomd_cafe24_main_page");
        // else setIframePageId("robomd_cafe24_category_page");
    }, []);
    
    useEffect(() => {
        if (mainDisplayGroup) loadCafe24MainProductList()
    }, [mainDisplayGroup, loadCafe24MainProductList]);
    
    useEffect(() => {
        if (analysisStandardValue) reloadProductByAnalysisStandard()
    }, [analysisStandardValue]);

    useEffect(() => {
        if (cafe24CategoryDisplayGroup && cafe24CategoryNo) loadCafe24CategoryProductList()
    }, [cafe24CategoryNo, cafe24CategoryDisplayGroup, cafe24CategoryNoList, loadCafe24CategoryProductList])

    useEffect(() => {
        // const fetchData = async () => {
            try {
                if (iframePageId === "robomd_cafe24_main_page") {
                    // 카테고리 분류 초기화
                    setCafe24CategoryNo("");
                    setCafe24CategoryDisplayGroup(1);
                    
                    // 메인 분류 조회
                    // await 
                    loadUpperCodeListCategoryList();
                }
                else {
                    // 메인 분류 초기화
                    setMainDisplayGroup("");
                   
                    // 카테고리 분류 조회
                    // await 
                    loadCafe24Category(1, 1);
                } 
            } catch (error) {
                console.error("Error in Change iframePageId fetchData: ", error);
            }
        // };
        // fetchData();
    }, [iframePageId])

    return (
        <>
            <div id='common-page-wrapper'>
                <div id="list_wrap" class="style_guide_v2 rp_setting_page">
                    <section class="list_admin_wrap">
                        <div class="list_admin_side_menu">
                            <div class="list_admin_side_menu_wrap">
                                <div className="list_admin_side_row_3 list_expand_wrap" ref={highlightConnect} style={{height:"auto", overflow:"visible", marginTop:"0px"}}>
                                    <div class="list_admin_side_title_1 list_admin_side_design_expand list_expand cafe24_list_expand" id="install_list_expand" style={{height:"auto", overflow:"visible"}}>
                                        <h3 class="text_16">위치 설정</h3>
                                    </div>
                                    <div class="list_admin_side_row_3_1 list_expand_1">
                                        <div class="list_admin_side_row_3_1c" style={{marginTop : "0px"}}>
                                            <h3 class="text_small">
                                                진열 위치
                                            </h3>
                                                <div class="select_list_position_disabled">
                                                    <SelectBoxV2 
                                                        id={"selectListPosition"}
                                                        width="100%"
                                                        height="36px"
                                                        padding="14px 36px 14px 16px"
                                                        list= {iframePageIdList}
                                                        optionTexttName = "val1"
                                                        optionValuetName = "code_name"
                                                        selectedValue = {iframePageId}
                                                        onClickOption = {(t, v, i) => setIframePageId(v)}
                                                    />
                                                </div>
                                            
                                            {/* 메인 진열 카테고리 */}
                                            {iframePageId === "robomd_cafe24_main_page"
                                                ? 
                                                <div style={{marginTop: "5px"}}>
                                                    <SelectBoxV2
                                                        id={"cafe24_main_select_list"}
                                                        tabIndex={0}
                                                        width="100%"
                                                        height="36px"
                                                        padding="14px 36px 14px 16px"
                                                        list= {mainCategoryList}
                                                        optionTexttName = "group_name"
                                                        optionValuetName = "display_group"
                                                        selectedValue = {mainDisplayGroup}
                                                        onClickOption={(name, value) => setMainDisplayGroup(value)}
                                                    />
                                                </div>
                                                : <></>
                                            }
                                            {/* 카페24 카테고리 진열 상품분류 */}
                                            {iframePageId === "robomd_cafe24_category_page"
                                                ?<>
                                                <h3 class="text_small list_admin_side_design_title">
                                                    상품분류
                                                </h3>
                                                <div class="list_select_margin" style={{width:"100%"}}>
                                                    {/** 카테고리 대분류 */}
                                                    <div class="list_category_select_box">
                                                        {<SelectBoxV2
                                                            id={"cafe24_category_0"}
                                                            tabIndex={0}
                                                            width="100%"
                                                            height="36px"
                                                            padding="14px 36px 14px 16px"
                                                            list= {cafe24CategoryList[0]}
                                                            optionTexttName = "category_name"
                                                            optionValuetName = "category_no"
                                                            selectedValue = {cafe24CategoryNoList[0] == "" ? "전체상품" : cafe24CategoryNoList[0]}
                                                            onClickOption={(name, value, id) => onChangeCafe24Category(name, value, id)}/>
                                                        }
                                                    </div>
                                                    {
                                                        cafe24CategoryList[1] != null && cafe24CategoryList[1].length > 0 
                                                        ?
                                                        <div class="list_category_select_box list_category_select_box_1">
                                                            {<SelectBoxV2
                                                                id={"cafe24_category_1"}
                                                                tabIndex={0}
                                                                width="100%"
                                                                height="36px"
                                                                padding="14px 36px 14px 16px"
                                                                list= {cafe24CategoryList[1]}
                                                                optionTexttName = "category_name"
                                                                optionValuetName = "category_no"
                                                                selectedValue = {cafe24CategoryNoList[1] == "" ? "전체상품" : cafe24CategoryNoList[1]}
                                                                onClickOption={(name, value, id) => onChangeCafe24Category(name, value, id)}/>
                                                            }
                                                        </div>
                                                        :   ""
                                                    }
                                                    {
                                                        cafe24CategoryList[2] != null && cafe24CategoryList[2].length > 0 
                                                        ?<div class="list_category_select_box list_category_select_box_1">
                                                            {<SelectBoxV2
                                                                id={"cafe24_category_2"}
                                                                tabIndex={0}
                                                                width="100%"
                                                                height="36px"
                                                                padding="14px 36px 14px 16px"
                                                                list= {cafe24CategoryList[2]}
                                                                optionTexttName = "category_name"
                                                                optionValuetName = "category_no"
                                                                selectedValue = {cafe24CategoryNoList[2] == "" ? "전체상품" : cafe24CategoryNoList[2]}
                                                                onClickOption={(name, value, id) => onChangeCafe24Category(name, value, id)}/>
                                                            }
                                                        </div>
                                                        : ""
                                                    }
                                                    {
                                                        cafe24CategoryList[3] != null && cafe24CategoryList[3].length > 0 
                                                        ? <div class="list_category_select_box list_category_select_box_1">
                                                            {<SelectBoxV2
                                                                id={"cafe24_category_3"}
                                                                tabIndex={0}
                                                                width="100%"
                                                                height="36px"
                                                                padding="14px 36px 14px 16px"
                                                                list= {cafe24CategoryList[3]}
                                                                optionTexttName = "category_name"
                                                                optionValuetName = "category_no"
                                                                selectedValue = {cafe24CategoryNoList[3] == "" ? "전체상품" : cafe24CategoryNoList[3]}
                                                                onClickOption={(name, value, id) => onChangeCafe24Category(name, value, id)}/>
                                                            }
                                                        </div>
                                                        : ""
                                                    }
                                                    {/* 카테고리 진열 상세 상품분류 */}
                                                    <h3 class="text_small list_admin_side_design_title">
                                                    <div style={{whiteSpace:"nowrap"}}>
                                                            상세 상품분류
                                                        </div>
                                                    </h3>
                                                    <div class="list_admin_side_row_3_1c_radio">
                                                        {
                                                            cafe24CategoryDisplayGroupList.map((obj, idx) => (
                                                                <RadioButton
                                                                    id={"cafe24_category_display_group_"+obj.value}
                                                                    name={"category_display_group"}
                                                                    value={obj.value}
                                                                    text={obj.title}
                                                                    checked={cafe24CategoryDisplayGroup==obj.value}
                                                                    onChangeCheck={(e) => setCafe24CategoryDisplayGroup(e.target.value)}/>
                                                            ))
                                                        }
                                                    </div>
                                                    
                                                </div>
                                            </>
                                            :""}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="list_admin_main_menu">
                            <div class="list_admin_main_menu_wrap">
                                <div class="list_admin_main_menu_row_1">
                                    <div class="list_admin_main_menu_row_1_left">
                                        <div className="list_admin_main_menu_row_1_left_sync">
                                            <h2 class="text_16">통계 보기 &nbsp;</h2>
                                            <div style={{color: "#878B90"}}>총 <span style={{color: "#00ACB2"}}>{productList.length}</span>개 &nbsp;&nbsp;</div>
                                        </div>
                                    </div>
                                    <div class="list_admin_main_menu_row_1_right">
                                                                                        
                                    </div>
                                </div>
                                {/** 상품 목록 테이블 */}
                                <div class="list_admin_main_board">
                                    {/** 테이블 상단 상호작용 */}
                                    <div class="list_main_board_row_1" style={getCurrentPath() === "category" ? {marginBottom: "10px", overflow: "visible", flexWrap:"wrap"} : {overflow: "visible", flexWrap:"wrap"}}>
                                        <div id={getCurrentPath() == "category" ? "eventItemAnalysisStandardAreaForCategoryPage" : "eventItemAnalysisStandardArea"}>
                                            통계수 분석기간
                                            <div style={{flex: 1, marginLeft:"10px"}}>
                                                <SelectBoxV2
                                                    style={{width:"100%"}}
                                                    id={"eventItemAnalysisStandard"}
                                                    tabIndex={0}
                                                    width="100%"
                                                    height="36px"
                                                    padding="14px 36px 14px 16px"
                                                    list= {eventItemAnalysisStandardList}
                                                    optionTexttName = "title"
                                                    optionValuetName = "value"
                                                    selectedValue = {analysisStandardValue}
                                                    onClickOption={(name, value, id) => setAnalysisStandardValue(value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/** 상품 리스트 */}
                                    <div className={"list_main_board_table_wrap"}>
                                        <table className={"list_main_board_table rp_list_main_table"}
                                            onLoad={e => onLoadMainTable()}>
                                            <thead>
                                                <tr>
                                                    <td class="list_main_td input_table_idx">순서</td>
                                                    <td class="list_main_td table_image">사진</td>
                                                    <td class="list_main_td product_name">상품명</td>
                                                    <td class="list_main_td reg_date">상품등록일</td>
                                                    <td class="list_main_td hit_cnt">조회수</td>
                                                    <td class="list_main_td cart_cnt">장바구니수</td>
                                                    {/* <td class="list_main_td wish_cnt">찜</td> */}
                                                    <td class="list_main_td price">판매가</td>
                                                    <td class="list_main_td pc_discount_price">할인가</td>
                                                    <td class="list_main_td order_cnt">판매수</td>
                                                    <td class="list_main_td order_price">판매액</td>
                                                    <td class="list_main_td review_cnt">리뷰수</td>
                                                    <td class="list_main_td review_point">리뷰 평점</td>
                                                    <td class="list_main_td quantity">재고수</td>
                                                </tr>
                                            </thead>   
                                            {!showLoadingBar ? (
                                                <tbody id="main_table_tbody">
                                                    {productList.map((value, index) => (
                                                        <tr
                                                            style={{
                                                                height: "600 !important",
                                                                overflowY: 'scroll !important',
                                                                overflowX: 'hidden !important',
                                                            }}
                                                            key={value.product_no}
                                                            tabIndex={-1}
                                                        >
                                                            <LazyLoad 
                                                            offset="300"
                                                            once="true"
                                                            scrollContainer="#main_table_tbody"
                                                            throttle={100}
                                                            placeholder={<td style={{height:"60px"}}></td>}
                                                            style={{ width: '100%' }}>
                                                                <td className={"input_table_idx"}>
                                                                    {index + 1}
                                                                    {   
                                                                        value.fixed_sort
                                                                        ? 
                                                                            <img 
                                                                            alt='fixed_sort'
                                                                            src={fix_icon} 
                                                                            style={{cursor:"default"}}
                                                                            />
                                                                        : null
                                                                    }
                                                                </td>
                                                                <td className={"table_image"}>
                                                                    <img src={value.image_url} alt={value.product_name} />
                                                                </td>
                                                                <td className={"product_name"}>{value.product_name}</td>
                                                                <td className={"reg_date"}>{value.created_date}</td>
                                                                <td className={"hit_cnt"}>{value.hit_cnt}</td>
                                                                <td className={"cart_cnt"}>{value.cart_cnt}</td>
                                                                <td className={"price"}>{value.price}</td>
                                                                <td className={"pc_discount_price"}>{value.pc_discount_price}</td>
                                                                <td className={"order_cnt"}>{value.order_cnt}</td>
                                                                <td className={"order_price"}>{value.order_price}</td>
                                                                <td className={"review_cnt"}>{value.review_cnt}</td>
                                                                <td className={"review_point"}>{value.review_point}</td>
                                                                <td className={"quantity"}>{value.quantity}</td>
                                                            </LazyLoad>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            ) : (
                                                <tbody style={{position: "relative", height:"200px"}}>
                                                    <div style={{width: $(".list_main_board_table_wrap").width(), height:"200px", display:"flex", justifyContent:"center"}}>
                                                        <img src={round_loading_bar} style={{width:"200px"}}></img>
                                                    </div>
                                                </tbody>
                                            )}
                                        </table>        
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </>
    )
}

export default RpProductListStatistics