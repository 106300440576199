import React from 'react';
import http from './modules/api';
import SignupForm_trend from './SignupForm_trend';
import SignupForm_cafe24 from './SignupForm_cafe24';
import ReactGA from 'react-ga';
// import ReactPixel from 'react-facebook-pixel';
import {ga4react, reactPixel} from './Menu'
import { setCookie } from "./modules/utils/CookieUtil";
import Button from "./modules/common/Button";
import $ from 'jquery';
import { withTranslation } from "react-i18next";
ReactGA.initialize('UA-78553461-8');

// ReactPixel.init('438227415050093');
reactPixel.pageView();

var ga_label = sessionStorage.getItem("userName");
var mall_id = sessionStorage.getItem("mall_id");

class SignupForm extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
			user_id: '',
            password: '',
			passwordConfirm: '',
			corp_reg_no : '',
			corp_name : '',
			emp_cnt : '',
			user_name : '',			
			corp_addr : '',
			corp_addr2 : '',
			tel_no : '', // 전화번호 (휴대폰 번호로 임력해야함)
			email : this.props.email,
			token : '',
			pass_yn : false,
			biz_id_pass_yn : false,
			mall_id : this.props.mall_id,
			
			/* 새로 추가 되는 항목 */
			manager_name: '', 	// 담당자 이름
			manager_email: '',	// 담당자 이메일
			manager_tel_no: '',	// 당당자 전화번호 (휴대폰 번호로 임력해야함)
			hosting_type : '',

			/* 약관 동의 리스트 */
			form_agree_list: [],

			/* robomd 서비스 리스트 */
			service_list: [],
			/* 신청 서비스 리스트 */
			req_service_list: [],

			/* 회원가입 3단계 */
			bizId_pass_yn : "",
			biz_txt_case : "",
			email_pass_yn : "",
			tel_pass_yn : "",
			btn_chk_yn : "N",
			btn_pass_yn : "N",

			/* 버튼 */
			btn_next : false,
			btn_user_id : false,
		};
	}

	componentDidMount() {
		//서비스 리스트 조회
		this.getRobomdServiceList();
		console.log("### this.props.service_type : " + this.props.service_type);
		//서비스 구분
		if(this.props.service_type != null && this.props.service_type != '' && this.props.service_type != undefined) {
			this.onClickServiceType('', 'Y', this.props.service_type);
			// Cafe24 - 타겟뷰 유입시 배너 서비스 신청 추가
			if (this.props.service_type == '01009') {
				this.state.req_service_list.push("01001");
				this.setState({ ["01001"] : "Y" });
			}
		}
		//소상공인 서비스 가입시 사업자등록번호 입력 제한 해제
		// if (this.props.service_type == "01003") {
		// 	this.setState({biz_id_pass_yn: true});
		// }
    }

	componentDidUpdate() {
		if (this.props.mall_id != null && this.state.mall_id != null  && this.state.mall_id == '') {
			this.setState({"mall_id": this.props.mall_id});
		} else if (mall_id != null && mall_id != '' && this.state.mall_id == null && this.state.mall_id == '') {
			this.setState({"mall_id": mall_id});
		}
	}

	handleText = (e, key) => {
		// computed property
		if (key == "user_id" || key == "password") {
			//공백제거
			this.setState({[key]: this.setWhiteSpaceRemove(e.target.value)});
		} else if (key == "mall_id"){
			//앞뒤 공백제거
			this.setState({[key]: e.target.value.trim() });
		} else {
			this.setState({[key]: e.target.value},() => {
				if(this.props.step == 3) {
					this.bizIdValid(this.state.corp_reg_no);
					this.emailValid(this.state.email);
					this.telValid(this.state.tel_no);
					this.signupValidChk(this.state);
				}
			});
		}
	}

	handleTextId = (e, key) => {
		// computed property
		if (key == "user_id" || key == "password") {
			//공백제거
			this.setState({[key]: this.setWhiteSpaceRemove(e.target.value)});
		} else if (key == "mall_id"){
			//앞뒤 공백제거
			this.setState({[key]: e.target.value.trim() });
		} else {
			this.setState({[key]: e.target.value});
		}
		
		// "아이디 중복확인" 버튼 활성화/비활성화
		let userId = document.getElementById("user_id");

		if(userId.value == "" || userId.value.length < 3) {
			// input값 변경 시 success 해제
			userId.classList.remove("success");
			this.setState({btn_user_id : false})
		}
		else if(userId.value.length >= 3) {
			// input값 변경 시 success 해제
			userId.classList.remove("success");
			this.setState({btn_user_id : true})
		}
	}

	handleTextBizId = (e, key) => {
		// computed property
		if (key == "user_id" || key == "password") {
			//공백제거
			this.setState({[key]: this.setWhiteSpaceRemove(e.target.value)});
		} else if (key == "mall_id"){
			//앞뒤 공백제거
			this.setState({[key]: e.target.value.trim() });
		} else {
			this.setState({[key]: e.target.value},() => {
				if(this.props.step == 3) {
					this.bizIdValid(this.state.corp_reg_no);
					this.emailValid(this.state.email);
					this.telValid(this.state.tel_no);
					this.signupValidChk(this.state);
					this.setState({
						bizId_pass_yn : "",
						biz_txt_case : ""
					});
				}
			});
		}
	}

	// "다음 단계로" 버튼 활성화/비활성화
	goNext() {
		let user = {
			id : document.getElementById("user_id"),
			pw : document.getElementById("password"),
			pwCnfm : document.getElementById("passwordConfirm"),
		}

		const btn_next = user.id.classList.contains("success") && user.pw.classList.contains("success") && user.pwCnfm.classList.contains("success");
		// update 무한으로 타는것 방지
		if((!this.state.btn_next && btn_next) || (this.state.btn_next && !btn_next)){
			this.setState({btn_next : btn_next});
		}

		// 마케팅(비회원) 로보 이미지 프롬프트가 존재하면, 배너 서비스가 선택되도록 한다
		if(this.props.non_members_robo_img_prompt && this.state.req_service_list.length == 0){
			this.setState({
				req_service_list : ["01001"],
				["01001"] : "Y",
			});
		}
	}

	// "가입" 버튼 활성화/비활성화
	signupValidChk = (obj) => {
		// if(obj.corp_name == null || obj.corp_name == "") { // 회사 이름 체크
		// 	this.setState({btn_pass_yn:"N"});
		// }
		if(!obj.user_name) { // 신청담담자 이름 체크
			this.setState({btn_pass_yn:"N"});
		}
		else if(!obj.tel_no || obj.tel_pass_yn != "Y") { // 연락처 체크
			this.setState({btn_pass_yn:"N"});
		}
		else if(!obj.email || obj.email_pass_yn != "Y") { // 이메일 주소 체크
			this.setState({btn_pass_yn:"N"});
		}
		else if(obj.corp_reg_no && obj.bizId_pass_yn != "Y") { // 사업자 등록번호 체크
			this.setState({btn_pass_yn:"N"});
		}
		else {
			this.setState({btn_pass_yn:"Y"});
		}
	}

	// 이메일 유효성 검사
	emailValidChk = (obj) => {
		// [알파벳+숫자] + @ + [알파벳+숫자] + . + [알파벳+숫자] 패턴 체크
		let pattern = /^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-Za-z0-9\-]+/;

		if(pattern.test(obj) == false) {
			return false;
		}
		else {
			return true;
		}
	}

	emailValid(obj) {
		let email_pass_yn = "";

		if(this.emailValidChk(obj) == false) {
			if(obj == null || obj == "") {
				email_pass_yn = ""
			}
			else {
				email_pass_yn = "N"
			}
		}
		else {
			email_pass_yn = "Y";
		}

		this.setState({
			email_pass_yn : email_pass_yn
		}, () => {
			this.signupValidChk(this.state);
		});
	}

	// 전화번호 유효성 검사
	telValidChk = (obj) => {
		// [01로 시작하는 핸드폰번호 / 02로 시작하는 집 전화번호 / 03,05,07 등으로 시작하는 지역번호] + (-) + [숫자 3~4자리] + (-) + [숫자 4자리] 패턴 체크
		let pattern = /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})-?[0-9]{3,4}-?[0-9]{3,4}$/;

		if(pattern.test(obj) == false) {
			return false;
		}
		else {
			return true;
		}
	}

	telValid(obj) {
		let tel_pass_yn;

		if(this.telValidChk(obj) == false) {
			if(obj == null || obj == "") {
				this.setState({tel_pass_yn : ""});
				tel_pass_yn = "";
			}
			else {
				this.setState({tel_pass_yn : "N"});
				tel_pass_yn = "N";
			}
		}
		else {
			this.setState({tel_pass_yn : "Y"});
			tel_pass_yn = "Y";
		}
		
		this.setState({
			tel_pass_yn : tel_pass_yn
		}, () => {
			this.signupValidChk(this.state);
		});
	}

	// 사업자 등록번호 확인 활성화/비활성화
	bizIdValid(obj) {
		let thisObj = obj.replaceAll("-","");
		console.log(thisObj.length);

		if(thisObj == null || thisObj == "" || thisObj.length<10) {
			this.setState({btn_chk_yn : "N"});
		}
		else {
			this.setState({btn_chk_yn : "Y"});
		}
	}

	// 구글 애널리틱스(GA4) 이벤트, Facebook Meta Pixel 이벤트
	handleUserIdOnBlur = () => {
		ga4react.event('[회원가입] Step1: 아이디 입력', '아이디 입력', '회원가입');
		reactPixel.trackCustom('[회원가입] Step1: 아이디 입력');
	}

	handleUserPwOnBlur = () => {
		ga4react.event('[회원가입] Step1: 패스워드 입력', '패스워드 입력 ID=' + this.state.user_id, '회원가입');
		reactPixel.trackCustom('[회원가입] Step1: 패스워드 입력', {user_id : this.state.user_id});
	}

	handleUserPwChkOnBlur = () => {
		ga4react.event('[회원가입] Step1: 패스워드 확인', '패스워드 확인 ID=' + this.state.user_id, '회원가입');
		reactPixel.trackCustom('[회원가입] Step1: 패스워드 확인', {user_id : this.state.user_id});
	}

	handleCorpNameOnBlur = () => {
		ga4react.event('[회원가입] Step3: 회사 이름 입력', '회사 이름 입력 ID=' + this.state.user_id, '회원가입');
		reactPixel.trackCustom('[회원가입] Step3: 회사 이름 입력', {user_id : this.state.user_id});
	}

	handleBizIdOnBlur = () => {
		ga4react.event('[회원가입] Step3: 사업자등록번호 입력', '사업자등록번호 입력 ID=' + this.state.user_id, '회원가입');
		reactPixel.trackCustom('[회원가입] Step3: 사업자등록번호 입력', {user_id : this.state.user_id});
	}

	handleUserNameOnBlur = () => {
		ga4react.event('[회원가입] Step3: 신청 담당자 이름 입력', '신청 담당자 이름 입력 ID=' + this.state.user_id, '회원가입');
		reactPixel.trackCustom('[회원가입] Step3: 신청 담당자 이름 입력', {user_id : this.state.user_id});
	}

	handleEmailOnBlur = () => {
		ga4react.event('[회원가입] Step3: 회사 이메일 주소 입력', '회사 이메일 주소 입력 ID=' + this.state.user_id, '회원가입');
		reactPixel.trackCustom('[회원가입] Step3: 회사 이메일 주소 입력', {user_id : this.state.user_id});
	}

	handleEmailOkOnBlur = () => {
		ga4react.event('[회원가입] Step3: 회사 이메일 주소 입력 확인', '회사 이메일 주소 입력 확인 ID=' + this.state.user_id, '회원가입');
		reactPixel.trackCustom('[회원가입] Step3: 회사 이메일 주소 입력 확인', {user_id : this.state.user_id});
	}

	handleTelNoOnBlur = () => {
		ga4react.event('[회원가입] Step3: 연락처 입력', '연락처 입력 ID=' + this.state.user_id, '회원가입');
		reactPixel.trackCustom('[회원가입] Step3: 연락처 입력', {user_id : this.state.user_id});
	}

	handleTelNoOkOnBlur = () => {
		ga4react.event('[회원가입] Step3: 연락처 입력 확인', '연락처 입력 확인 ID=' + this.state.user_id, '회원가입');
		reactPixel.trackCustom('[회원가입] Step3: 연락처 입력 확인', {user_id : this.state.user_id});
	}

	handleHostingTypeOnBlur = () => {
		ga4react.event('[회원가입] Step3: 호스팅 입력', '호스팅 입력 ID=' + this.state.user_id, '회원가입');
		reactPixel.trackCustom('[회원가입] Step3: 호스팅 입력', {user_id : this.state.user_id});
	}

    //아이디 중복체크
	onClickIdChkButton = () => {
		//1. Validation Check
		if (this.state.user_id == null || this.state.user_id == "") {
			alert(this.props.t('regist.no_ID'));
			return false;
		}
		var pattern = /^[a-z0-9]{3,20}$/;
		if(!pattern.test(this.state.user_id)){
			// alert('영문소문자 , 숫자 3 ~20 자리로 입력해 주세요.')
			this.setState({user_id: ""});
			$("#user_id").addClass("wrong").removeClass("success");
			$("#user_vaidate_check").addClass("wrong").removeClass("success");
			$("#user_vaidate_check").text(this.props.t('regist.ID_rule'));
			return false;
		} else {
			$("#user_id").removeClass("wrong").addClass("success");
			$("#user_vaidate_check").removeClass("wrong").addClass("success");
			$("#user_vaidate_check").text("");
		}
		http.get("/rest/common/id/check", {params:{user_id:this.state.user_id}})
		.then(res => {
			this.setState({pass_yn: res.data.passYn}, () => {
			if (this.state.pass_yn != true) {
				this.setState({user_id: ""});
				$("#user_id").addClass("wrong").removeClass("success");
				$("#user_vaidate_check").addClass("wrong").removeClass("success");
				$("#user_vaidate_check").text(this.props.t('regist.already_use_ID'));

				ga4react.event('[회원가입] Step1: 아이디 확인 실패', '아이디 확인 실패', '회원가입');
				reactPixel.trackCustom('[회원가입] Step1: 아이디 확인 실패');

			} else {
				$("#user_id").removeClass("wrong").addClass("success");
				$("#user_vaidate_check").removeClass("wrong").addClass("success");
				$("#user_vaidate_check").text("");

				ga4react.event('[회원가입] Step1: 아이디 확인 성공', '아이디 확인 성공 ID=' + this.state.user_id, '회원가입');
				reactPixel.trackCustom('[회원가입] Step1: 아이디 확인 성공', {user_id : this.state.user_id});

			}
		});
		//    alert(res.data.retMsg);
			// $("#user_vaidate_check_txt").text(res.data.retMsg); // 중복체크 기능이라 메시지를 중복체크 메시지로 바꿈.
			$("#user_vaidate_check_txt").text(this.props.t('regist.already_use_ID')); // 중복체크 기능이라 메시지를 중복체크 메시지로 바꿈.
			//중복시 입력된 아이디 초기화
		})
		.catch(err => { 
			alert("아이디 중복체크 오류"); 
		});

		this.goNext();
    };

    //가입신청 완료
	onClickSignupButton = () => {
		const sendForm = {...this.state};
		//sendForm.user_join_cls = this.props.user_join_cls;
		sendForm.service_type = this.props.service_type;
		const config = {
			headers: {
				'content-type': 'application/json;'
			}
		}
		//4. 가입신청
		http
		.post("/signup", sendForm, config)
		.then(
			resultData => {		
			console.log(resultData);				 
			if (!resultData.data.success) {
				// 가입신청 - 가입신청 오류
				ReactGA.event({
					category: "가입신청 오류",
					action: "신청 업체명 : " + sendForm.corp_name,
					label: ga_label
				});
				ga4react.event('[회원가입] 회원가입 실패', '회원가입 실패 ID=' + sendForm.user_id, '회원가입');
				reactPixel.trackCustom('[회원가입] 회원가입 실패', {user_id : sendForm.user_id});
				alert("가입신청 오류");

				return false;	
			}
			// 가입신청 - 가입신청 완료
			ReactGA.event({
				category: "가입신청 완료",
				action: "신청 업체명 : " + sendForm.corp_name,
				label: ga_label
			});
			ga4react.event('[회원가입] 회원가입 완료', '회원가입 완료 ID=' + sendForm.user_id, '회원가입');
			reactPixel.trackCustom('[회원가입] 회원가입 완료', {user_id : sendForm.user_id});

			// 비연동형 회원가입
			if(this.props.regist_solution_type_cd=="09900"){
				setCookie('regist_solution_type_cd_09900_'+sendForm.user_id, "Y");
			}

			alert(this.props.t('regist.success_regist'));

			// 마케팅(비회원 접근) 로보 이미지 프롬프트 내용이 존재하는 경우 쿠키에 프롬프트와 user_id 저장
			if(this.props.non_members_robo_img_prompt){
				setCookie('non_members_robo_img_prompt_'+sendForm.user_id, encodeURIComponent(this.props.non_members_robo_img_prompt));
			}
			if(this.props.non_members_robo_img_design_yn){
				setCookie('non_members_robo_img_design_yn_'+sendForm.user_id, this.props.non_members_robo_img_design_yn);
			}

			// 동의한 약관_순번 리스트 저장
			this.fn_formAgreeListSave();
			// mall_id 제거
			sessionStorage.removeItem("mall_id");
			})
		.catch(
			error => { 
				// 가입신청 - 가입신청 오류
				ReactGA.event({
					category: "가입신청 오류",
					action: "신청 업체명 : " + sendForm.corp_name,
					label: ga_label
				});
				ga4react.event('[회원가입] 회원가입 실패', '회원가입 실패 ID=' + sendForm.user_id, '회원가입');
				reactPixel.trackCustom('[회원가입] 회원가입 실패', {user_id : sendForm.user_id});
				alert("회원가입에 실패하였습니다.\n관리자에게 문의해주세요(1599-3518)");
			}
		);

		// if (sendForm.corp_name == null || sendForm.corp_name == "") {
		// 	alert("회사 이름을 입력해 주세요.");

		// 	// 가입신청 - 가입신청 요청
		// 	ReactGA.event({
		// 		category: "가입신청 이벤트 요청",
		// 		action: "회사 이름을 입력해 주세요. : " + sendForm.corp_name + "(" + sendForm.user_id + ")",
		// 		label: ga_label
		// 	});
		// 	return false;
		// }
		// if (sendForm.corp_reg_no == null || sendForm.corp_reg_no == "") {
		// 	alert("사업자등록번호를 입력해 주세요.");

		// 	// 가입신청 - 가입신청 요청
		// 	ReactGA.event({
		// 		category: "가입신청 이벤트 요청",
		// 		action: "사업자등록번호를 입력해 주세요. : " + sendForm.corp_name + "(" + sendForm.user_id + ")",
		// 		label: ga_label
		// 	});
		// 	return false;
		// }
		// if (sendForm.biz_id_pass_yn != true) {
		// 	alert("사업자등록번호 확인을 해주세요.");

		// 	// 가입신청 - 가입신청 요청
		// 	ReactGA.event({
		// 		category: "가입신청 이벤트 요청",
		// 		action: "사업자등록번호 확인을 해주세요 : " + sendForm.corp_name + "(" + sendForm.user_id + ")",
		// 		label: ga_label
		// 	});

		// 	return false;
		// }
		// if (sendForm.user_name == null || sendForm.user_name == "") {
		// 	alert("대표자 이름을 입력해 주세요.");

		// 	// 가입신청 - 가입신청 요청
		// 	ReactGA.event({
		// 		category: "가입신청 이벤트 요청",
		// 		action: "대표자 이름을 입력해 주세요. : " + sendForm.corp_name + "(" + sendForm.user_id + ")",
		// 		label: ga_label
		// 	});
		// 	return false;
		// }
		// if (sendForm.email == null || sendForm.email == "") {
		// 	alert("이메일 주소를 입력해 주세요.");

		// 	// 가입신청 - 가입신청 요청
		// 	ReactGA.event({
		// 		category: "가입신청 이벤트 요청",
		// 		action: "이메일 주소를 입력해 주세요. : " + sendForm.email + " / " + sendForm.corp_name + "(" + sendForm.user_id + ")",
		// 		label: ga_label
		// 	});
		// 	return false;
		// }
		// if (sendForm.tel_no == null || sendForm.tel_no == "") {
		// 	alert("연락처를 입력해 주세요.\n(비밀번호 분실시 카카오 알림톡을 수신 가능한 연락처)");

		// 	// 가입신청 - 가입신청 요청
		// 	ReactGA.event({
		// 		category: "가입신청 이벤트 요청",
		// 		action: "연락처를 입력해 주세요. : " + sendForm.tel_no + " / " + sendForm.corp_name + "(" + sendForm.user_id + ")",
		// 		label: ga_label
		// 	});
		// 	return false;
		// }
	};

	// 동의한 약관_순번 리스트 저장
	async fn_formAgreeListSave() {

		const sendForm = {
			user_id: this.state.user_id,
			form_agree_list: this.props.form_agree_list
		};
		const config = {
            headers: {
                'content-type': 'application/json;'
            }
        };
		const res = await http.post("/rest/common/formAgreeSave", sendForm, config);

		//로그인 페이지로 이동.
		window.location.href = "/login";
	}

	//서비스 리스트 조회
    getRobomdServiceList() {
        http.get("/rest/common/serviceList",
            {
                params:{}
            })
	 	 	 .then(res => {
                this.setState({service_list : res.data});
	 	 	})
	 	 	 .catch(err => {
	 	 		 console.log("서비스 리스트 조회 에러");
	 	 	});
    }

	bizIdCheck = () => {
		const sendForm = {...this.state};

		if(this.state.btn_chk_yn == "Y") {
			if(!this.checkBizID(sendForm.corp_reg_no)) {
				// 에러 케이스 1 - 유효하지 않은 사업자 등록번호
				this.setState({
					bizId_pass_yn : "N",
					biz_txt_case : "error_case_1"
				});
	
				// 가입신청 - 가입신청 요청
				ReactGA.event({
					category: "가입신청 이벤트 요청",
					action: "유효하지 않은 사업자등록번호 입니다. : " + sendForm.corp_reg_no + " / " +sendForm.corp_name + "(" + sendForm.user_id + ")",
					label: ga_label
				});
				ga4react.event('[회원가입] Step3: 사업자등록번호 확인 실패', '사업자등록번호 확인 실패 ID=' + sendForm.user_id, '회원가입');
				reactPixel.trackCustom('[회원가입] Step3: 사업자등록번호 확인 실패', {user_id : sendForm.user_id});
				return false;
			} 
			else {
				http
				.get("/rest/common/corpRegNoDuplicated?corp_reg_no="+sendForm.corp_reg_no)
				.then(resultData => {
					if(Number(resultData.data.is_corp_reg_no_duplicated) == 1) {
						// 에러 케이스 2 - 중복 가입
						this.setState({
							bizId_pass_yn : "N",
							biz_txt_case : "error_case_2"
						});
	
						// 가입신청 - 가입신청 요청
						ReactGA.event({
							category: "가입신청 이벤트 요청",
							action: "가입한 내역이 있습니다. : " + sendForm.corp_reg_no + " / " +sendForm.corp_name + "(" + sendForm.user_id + ")",
							label: ga_label
						});
						ga4react.event('[회원가입] Step3: 사업자등록번호 확인 실패', '사업자등록번호 확인 실패 ID=' + sendForm.user_id, '회원가입');
						reactPixel.trackCustom('[회원가입] Step3: 사업자등록번호 확인 실패', {user_id : sendForm.user_id});

						alert("이미 등록된 사업자 등록번호 입니다.\n다른 사업자 등록번호를 입력해 주세요.")
	
						this.setState({biz_id_pass_yn: false});
						return false;
					} 
					else {
						// 성공 케이스
						this.setState({
							bizId_pass_yn : "Y",
							biz_txt_case : ""
						});
						this.signupValidChk(this.state);
	
						ga4react.event('[회원가입] Step3: 사업자등록번호 확인', '사업자등록번호 확인 ID=' + sendForm.user_id, '회원가입');
						reactPixel.trackCustom('[회원가입] Step3: 사업자등록번호 확인', {user_id : sendForm.user_id});
	
						this.setState({biz_id_pass_yn: true});
						return true;
					}
				})
				.catch(error => { 
					this.setState({biz_id_pass_yn: false});
					return false;
				});
			}
		}
		else {
			return false;
		}
	}

	//1-1. 패스워드 패턴 체크 메세지
	passwordRule() {
		const { password } = this.state;
		if (password) {
			if (!this.doesPasswordRule(password)) {
				return (	
						<div id="passwd_vaidate_check" className="vaidate_check flexBox">3~20자의 영어 소문자와 숫자만 사용 가능합니다.</div>
				);
			}
		}
	}
	//1-2. 패스워드 패턴 
	// doesPasswordRule(password) {
	doesPasswordRule() {
		// const passRule = /^.*(?=.{6,15})(?=.*[0-9])(?=.*[a-zA-Z]).*$/;
		// const passRule = /^.*(?=.{6,15})(?=.*[0-9])(?=.*[a-zA-Z]).*$/;
		// return passRule.test(password);
		const { password } = this.state;

		if (password == null || password == "") {
			$("#password").removeClass("wrong")
			$("#password_vaidate_check").removeClass("wrong")
			$("#password_vaidate_check").text("8 ~ 20자 영문 대 소문자, 숫자, 특수문자를 사용하세요.");
			return false;
		}

		var num = password.search(/[0-9]/g);
		var eng = password.search(/[a-z]/ig);
		var spe = password.search(/[`~!@@#$%^&*|₩₩₩'₩";:₩/?]/gi);
	   
		if(password.length < 8 || password.length > 20){
			$("#password").addClass("wrong").removeClass("success");
			$("#password_vaidate_check").addClass("wrong").removeClass("success");
			$("#password_vaidate_check").text(this.props.t('regist.PW_rule'));
			// alert("8자리 ~ 20자리 이내로 입력해주세요.");
			// return false;
		} else if(password.search(/\s/) != -1){
			if (!$("#password").hasClass("wrong")) {
				$("#password").addClass("wrong").removeClass("success");
			}
			if (!$("#password_vaidate_check").hasClass("wrong")) {
				$("#password_vaidate_check").addClass("wrong").removeClass("success");
			}
			$("#password_vaidate_check").text("비밀번호는 공백 없이 입력해주세요.");
			// alert("비밀번호는 공백 없이 입력해주세요.");
			// return false;
		} else if(num < 0 || eng < 0 || spe < 0 ){
			if (!$("#password").hasClass("wrong")) {
				$("#password").addClass("wrong").removeClass("success");
			}
			if (!$("#password_vaidate_check").hasClass("wrong")) {
				$("#password_vaidate_check").addClass("wrong").removeClass("success");
			}
			$("#password_vaidate_check").text(this.props.t('regist.PW_rule2'));
			// alert("영문,숫자, 특수문자를 혼합하여 입력해주세요.");
			// return false;
		} else {
			$("#password").removeClass("wrong").addClass("success");
			$("#password_vaidate_check").removeClass("wrong").addClass("success");
			$("#password_vaidate_check").text("사용 가능한 비밀번호 입니다.");
			// console.log("사용 가능한 비밀번호 입니다."); 
			// return true;
		}
		this.goNext();
	}
	//2-1. 패스워드 확인 메세지
	passwordMatch() {
		const { password, passwordConfirm } = this.state;
		if (passwordConfirm) {
			if (!this.doesPasswordMatch(password, passwordConfirm)) {
				$("#passwordConfirm").addClass("wrong").removeClass("success");
				this.goNext();
				if (this.doesPasswordMatch(password, this.setWhiteSpaceRemove(passwordConfirm))) {
					return (
							// <h5 style={{color:'red'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;* 패스워드가 일치하지 않습니다. (공백을 제거해 주세요.)</h5>
							<div id="passwordConfirm" className="vaidate_check wrong flexBox">{this.props.t('regist.not_match_blank_PW')}</div>
					);
				}
				return (
						// <h5 style={{color:'red'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;* 패스워드가 일치하지 않습니다.</h5>
						<div id="passwordConfirm" className="vaidate_check wrong flexBox">{this.props.t('regist.not_match_PW')}</div>
				);
			} else {
				$("#passwordConfirm").removeClass("wrong").addClass("success");
				this.goNext();
				return (
						<div id="passwordConfirm" className="vaidate_check flexBox">{this.props.t('regist.password_matched')}</div>
				);
			}
		} 
	}
	//2-2. 패스워드 매칭 
	doesPasswordMatch(password, passwordConfirm) {
		return password === passwordConfirm;
	}

	//사업자 등록번호 유효성 체크
	checkBizID(bizID) {
		// bizID는 숫자만 10자리로 해서 문자열로 넘긴다.
        var checkID = new Array(1, 3, 7, 1, 3, 7, 1, 3, 5, 1);
        var tmpBizID, i, chkSum = 0, c2, remander;
		bizID = bizID.replace(/-/gi, '');
		
		//console.log(bizID)

        for (i = 0; i <= 7; i++)
            chkSum += checkID[i] * bizID.charAt(i);
        c2 = "0" + (checkID[8] * bizID.charAt(8));
        c2 = c2.substring(c2.length - 2, c2.length);
        chkSum += Math.floor(c2.charAt(0)) + Math.floor(c2.charAt(1));
        remander = (10 - (chkSum % 10)) % 10;

        if (Math.floor(bizID.charAt(9)) == remander) return true;
        else return false;
	}

	//store_temp 조회
	getStore = (data) => {
		this.setState(data);
	}

	//공백제거
	setWhiteSpaceRemove(str) {
		return str.replace(/(\s*)/g, '')
		// return str.replaceAll(' ', '')
	}

	//숫자만 입력
	number_keydown = e => {
        if (e.keyCode === 69 || e.keyCode === 107 || e.keyCode === 109 || e.keyCode === 189) {
            /* e, -, + 막기 */
            e.preventDefault(); 
        } 
    }

	// validateCheck and next step
	onClickNextStep = () => {

		const sendForm = {...this.state};
		//sendForm.user_join_cls = this.props.user_join_cls;

		if (this.props.step == 1 && !this.state.btn_next) {
			return false;
		}
		console.log("클릭")
		// else if (this.props.step == 3) {
		// 	if (sendForm.corp_name == null || sendForm.corp_name == "") {
		// 		alert("회사 이름을 입력해 주세요.");
	
		// 		// 가입신청 - 가입신청 요청
		// 		ReactGA.event({
		// 			category: "가입신청 이벤트 요청",
		// 			action: "회사 이름을 입력해 주세요. : " + sendForm.corp_name + "(" + sendForm.user_id + ")",
		// 			label: ga_label
		// 		});
		// 		return false;
		// 	}
		// 	if (sendForm.corp_reg_no == null || sendForm.corp_reg_no == "") {
		// 		alert("사업자등록번호를 입력해 주세요.");
	
		// 		// 가입신청 - 가입신청 요청
		// 		ReactGA.event({
		// 			category: "가입신청 이벤트 요청",
		// 			action: "사업자등록번호를 입력해 주세요. : " + sendForm.corp_name + "(" + sendForm.user_id + ")",
		// 			label: ga_label
		// 		});
		// 		return false;
		// 	}
		// 	if (sendForm.biz_id_pass_yn != true) {
		// 		alert("사업자등록번호 확인을 해주세요.");
	
		// 		// 가입신청 - 가입신청 요청
		// 		ReactGA.event({
		// 			category: "가입신청 이벤트 요청",
		// 			action: "사업자등록번호 확인을 해주세요 : " + sendForm.corp_name + "(" + sendForm.user_id + ")",
		// 			label: ga_label
		// 		});
	
		// 		return false;
		// 	}
		// 	if (sendForm.user_name == null || sendForm.user_name == "") {
		// 		alert("대표자 이름을 입력해 주세요.");
	
		// 		// 가입신청 - 가입신청 요청
		// 		ReactGA.event({
		// 			category: "가입신청 이벤트 요청",
		// 			action: "대표자 이름을 입력해 주세요. : " + sendForm.corp_name + "(" + sendForm.user_id + ")",
		// 			label: ga_label
		// 		});
		// 		return false;
		// 	}
		// 	if (sendForm.email == null || sendForm.email == "") {
		// 		alert("이메일 주소를 입력해 주세요.");
	
		// 		// 가입신청 - 가입신청 요청
		// 		ReactGA.event({
		// 			category: "가입신청 이벤트 요청",
		// 			action: "이메일 주소를 입력해 주세요. : " + sendForm.email + " / " + sendForm.corp_name + "(" + sendForm.user_id + ")",
		// 			label: ga_label
		// 		});
		// 		return false;
		// 	}
		// 	if (sendForm.tel_no == null || sendForm.tel_no == "") {
		// 		alert("연락처를 입력해 주세요.\n(비밀번호 분실시 카카오 알림톡을 수신 가능한 연락처)");
	
		// 		// 가입신청 - 가입신청 요청
		// 		ReactGA.event({
		// 			category: "가입신청 이벤트 요청",
		// 			action: "연락처를 입력해 주세요. : " + sendForm.tel_no + " / " + sendForm.corp_name + "(" + sendForm.user_id + ")",
		// 			label: ga_label
		// 		});
		// 		return false;
		// 	}
		// 	if (sendForm.hosting_type == null || sendForm.hosting_type == "") {
		// 		alert("호스팅을 입력해 주세요.");
	
		// 		// 가입신청 - 가입신청 요청
		// 		ReactGA.event({
		// 			category: "가입신청 이벤트 요청",
		// 			action: "호스팅을 입력해 주세요. : " + sendForm.hosting_type + " / " + sendForm.corp_name + "(" + sendForm.user_id + ")",
		// 			label: ga_label
		// 		});
		// 		return false;
		// 	}
		// }

		this.props.this_parent.setState({step: Number(Number(this.props.this_parent.state.step)+1)}) // // 다음 step으로 이동
		window.scrollTo(0,0);
	}

	onClickManagerYn = (e, type) => {
		this.setState({manager_yn : type});
	}

	onClickServiceType = (e, state, type) => {
		this.setState({[type] : state});
		if (state == "Y") {
			this.state.req_service_list.push(type);

			if(type==="01001"){
				ga4react.event('[회원가입] Step1: 서비스 선택(배너)', '서비스 선택(배너) ID=' + this.state.user_id, '회원가입');
				reactPixel.trackCustom('[회원가입] Step1: 서비스 선택(배너)', {user_id : this.state.user_id});
			}else if(type==="01002"){
				ga4react.event('[회원가입] Step1: 서비스 선택(진열)', '서비스 선택(진열) ID=' + this.state.user_id, '회원가입');
				reactPixel.trackCustom('[회원가입] Step1: 서비스 선택(진열)', {user_id : this.state.user_id});
			}else if(type==="01009"){
				ga4react.event('[회원가입] Step1: 서비스 선택(타겟뷰)', '서비스 선택(타겟뷰) ID=' + this.state.user_id, '회원가입');
				reactPixel.trackCustom('[회원가입] Step1: 서비스 선택(타겟뷰)', {user_id : this.state.user_id});
			}

		} else {
			this.state.req_service_list.splice(this.state.req_service_list.indexOf(type), 1);
		}

	}

    render () {
		const { t } = this.props;

        return (
                <div className={["signupForm", t('language')].join(' ')}>
                    {/* <h3>회원가입</h3> */}
					{
						this.props.step == 1
						?
							<>
								<div className={"form-group m-b-lg form_group_id"}>
									<label>{t('regist.ID')}<small className="text-navy"> *</small></label>
									<div className="input-group">
										<input type="text" id="user_id" placeholder={t('regist.ID_placeholder')} className="join-form form-control"
											value={this.state.user_id} 
											onChange={(e) => this.handleTextId(e, 'user_id')}
											onBlur={this.handleUserIdOnBlur}
											required minLength="3" maxLength="20"/>
										<span className="input-group-btn">
											<Button
												onClick={this.onClickIdChkButton}
												disabled={!this.state.btn_user_id}
												width={"100%"}
												height={"100%"}
												fontSize={"16px"}
											>
												{t('regist.check_ID_button')}
											</Button>
										</span>
									</div>
									<div id="user_vaidate_check" className="vaidate_check flexBox">{t('regist.ID_rule')}</div>	
								</div>
								<div className="form-group m-b-sm form_group_pw">
									<label>{t('regist.PW')}<small className="text-navy"> *</small></label>
									<div className="input-group">
										<input type="password" id="password" placeholder={t('regist.PW_placeholder')} className="join-form form-control"
											value={this.state.password} 
											onChange={(e) => this.handleText(e, 'password')}
											onBlur={this.handleUserPwOnBlur}
											required minLength="6" maxLength="15"/>
									</div>
									<div id="password_vaidate_check" className="vaidate_check flexBox">8 ~ 20자 영문 대 소문자, 숫자, 특수문자를 사용하세요.</div>
									{this.doesPasswordRule()}
								</div>
								<div className="form-group m-b-md form_group_pw_confirm">
									<div className="input-group">
										<input type="password" id="passwordConfirm" placeholder={t('regist.PW_check_placeholder')} className="join-form form-control"
											value={this.state.passwordConfirm}
											onChange={(e) => this.handleText(e, 'passwordConfirm')}
											   onBlur={this.handleUserPwChkOnBlur}
											required minLength="6" maxLength="15" />
									</div>
									{this.passwordMatch()}
								</div>
								{
									// _V2
									(this.state.service_list != null && this.state.service_list.length > 0 && this.props.service_type != '01003' && !this.props.non_members_robo_img_prompt && !this.props.regist_solution_type_cd)
									?
										<div className={["form-group", "m-b-sm", "form_group_service", t('language')].join(' ')}>
											<label>{t('regist.service_select_title')}</label>
											<div className="redio_v2">
												{this.state.service_list.map((service, idx) => (
													// <div className="flexBox radio_area m-b-sm">
													<div className="m-b-sm" style={{display:"flex", whiteSpace:"nowrap", flexWrap: "wrap"}}>
														<input type="radio" id="serviceCheck" onClick={e => this.goNext()}></input>
														{
															this.state[service.service_type] == "Y"
															? <label htmlFor="serviceCheck" className="radio on" onClick={e => this.onClickServiceType(e, "N", service.service_type)}></label>
															: <label htmlFor="serviceCheck" className="radio" onClick={e => this.onClickServiceType(e, "Y", service.service_type)}></label>
														}
														{/* <div className="radio_text" style={{margin: "auto 0 auto 14px"}}> */}
														<div className="radio_text">
															{service[t('regist.service_name')]}
														</div>
														<div className="radio_text_sub">{service[t('regist.service_desc')]}</div>
													</div> 
												))}
											</div>
										</div>
									: ''
								}
							</>
						: ""
					}
					{
						this.props.step == 3
						?
							<>
								<div className="form-group m-b-md">
									<label>{t('regist.userName')}<small className="text-navy"> *</small></label>
									<div className="input-group">
										<input type="text" id="user_name" className="form-control"
											value={this.state.user_name} 
											onChange={(e) => this.handleText(e, 'user_name')}
											onBlur={this.handleUserNameOnBlur}
											required maxLength="10" 
											placeholder={t('regist.userName_placeholder')}
										/>
									</div>
								</div>
								<div className="form-group m-b-md">
									<label>{t('regist.telephone')}<small className="text-navy"> *</small></label>
									{
										this.state.tel_pass_yn == ""
										? <>
											<div className="input-group">
												<input type="text" id="tel_no" className="join-form form-control" placeholder="***-****-****"
													value={this.state.tel_no} 
													onChange={(e) => this.handleText(e, 'tel_no')}
													onBlur={this.handleTelNoOnBlur}
													required maxLength="13" />
											</div>
											<div id="tel_vaidate_check" className="vaidate_check flexBox"></div>
										</>
										: this.state.tel_pass_yn == "N"
										? <>
											<div className="input-group">
												<input type="text" id="tel_no" className="join-form form-control wrong" placeholder="***-****-****"
													value={this.state.tel_no} 
													onChange={(e) => this.handleText(e, 'tel_no')}
													onBlur={this.handleTelNoOnBlur}
													required maxLength="13" />
											</div>
											<div id="tel_vaidate_check" className="vaidate_check flexBox" style={{display:"block"}}>{t('regist.telephone_not_correct')}</div>
										</>
										: this.state.tel_pass_yn == "Y"
										? <>
											<div className="input-group">
												<input type="text" id="tel_no" className="join-form form-control success" placeholder="***-****-****"
													value={this.state.tel_no} 
													onChange={(e) => this.handleText(e, 'tel_no')}
													onBlur={() => {this.handleTelNoOnBlur();this.handleTelNoOkOnBlur()}}
													required maxLength="13" />
											</div>
											<div id="tel_vaidate_check" className="vaidate_check flexBox"></div>
										</> 
										: ""
									}
								</div>
								<div className="form-group m-b-md">
									<label>{t('regist.Email')}<small className="text-navy"> *</small></label>
									{
										this.state.email_pass_yn == ""
										? <>
											<div className="input-group">
											<input type="text" id="email" className="form-control" placeholder="***@***.com"
												value={this.state.email} 
												onChange={(e) => this.handleText(e, 'email')}
												onBlur={this.handleEmailOnBlur}
												required maxLength="255" />
											</div>
											<div id="email_vaidate_check" className="vaidate_check flexBox"></div>
										</>
										: this.state.email_pass_yn == "N"
										? <>
											<div className="input-group">
											<input type="text" id="email" className="form-control wrong" placeholder="***@***.com"
												value={this.state.email} 
												onChange={(e) => this.handleText(e, 'email')}
												onBlur={this.handleEmailOnBlur}
												required maxLength="255" />
											</div>
											<div id="email_vaidate_check" className="vaidate_check flexBox" style={{display:"block"}}>{t('regist.email_not_correct')}</div>
										</>
										: this.state.email_pass_yn == "Y"
										? <>
											<div className="input-group">
											<input type="text" id="email" className="form-control success" placeholder="***@***.com"
												value={this.state.email} 
												onChange={(e) => this.handleText(e, 'email')}
												//onChange={(e) => {this.handleText(e, 'email'); this.emailValid()}}
												onBlur={() => {this.handleEmailOnBlur();this.handleEmailOkOnBlur()}}
												required maxLength="255" />
											</div>
											<div id="email_vaidate_check" className="vaidate_check flexBox"></div>
										</> : ""
									}
								</div>
								{t('language') == 'kr' &&
									<>
										<div className={"signupForm_corp_input_info_line"}></div>
										<div className={"signupForm_corp_input_info_text"}>
											기업 고객은 기업 맞춤 서비스 사용을 위해<br/>아래 정보를 입력해 주세요. (선택 사항)
										</div>
										<div className="form-group m-b-md">
											<label>회사 이름</label>
											<div className="input-group">
												<input type="text" id="corp_name" className="form-control"
														value={this.state.corp_name}
														onChange={(e) => this.handleText(e, 'corp_name')}
														onBlur={this.handleCorpNameOnBlur}
														required maxLength="50" 
														placeholder="회사명 입력"
												/>
											</div>
										</div>
										{
											// this.props.service_type == "01003" ? "" 
											<div className="form-group m-b-md">
												<label>사업자 등록번호</label>
												<div className="input-group">
													{
														this.state.biz_txt_case == "" && this.state.corp_reg_no && this.state.bizId_pass_yn != "Y"
														? <>
															<input id="biz_id" type="text" className="join-form form-control wrong" placeholder="***-**-*****"
																	value={this.state.corp_reg_no} 
																	onChange={(e) => this.handleTextBizId(e, 'corp_reg_no')}
																	onBlur={this.handleBizIdOnBlur}
																	required maxLength="12"
															/>
														</>
														: this.state.bizId_pass_yn == ""
														? <>
															<input id="biz_id" type="text" className="join-form form-control" placeholder="***-**-*****"
																	value={this.state.corp_reg_no} 
																	onChange={(e) => this.handleTextBizId(e, 'corp_reg_no')}
																	onBlur={this.handleBizIdOnBlur}
																	required maxLength="12"
															/>
														</>
														: this.state.bizId_pass_yn == "N"
														? <>
															<input id="biz_id" type="text" className="join-form form-control wrong" placeholder="***-**-*****"
																	value={this.state.corp_reg_no} 
																	onChange={(e) => this.handleTextBizId(e, 'corp_reg_no')}
																	onBlur={this.handleBizIdOnBlur}
																	required maxLength="12"
															/>
														</>
														: this.state.bizId_pass_yn == "Y"
														? <>
															<input id="biz_id" type="text" className="join-form form-control success" placeholder="***-**-*****"
																	value={this.state.corp_reg_no} 
																	onChange={(e) => this.handleTextBizId(e, 'corp_reg_no')}
																	onBlur={this.handleBizIdOnBlur}
																	required maxLength="12"
															/>
														</> : ""
													}
													<span className="input-group-btn">
														<Button
															className={["btn-auto-g", this.state.btn_chk_yn == "Y" && "cursor-pointer-important"].join(' ')}
															onClick={this.bizIdCheck}
															disabled={this.state.btn_chk_yn == "N"}
															width={"100%"}
															height={"100%"}
															fontSize={"14px"}
														>
															사업자 등록번호 확인
														</Button>
													</span>	
												</div>
												{
													this.state.biz_txt_case == "" && this.state.corp_reg_no && this.state.bizId_pass_yn != "Y"
													?<>
														<div id="biz_vaidate_check" className="vaidate_check flexBox" style={{display:"flex"}}>유효하지 않은 사업자등록번호 입니다.</div>
													</>
													: this.state.biz_txt_case == "error_case_1"
													? <>
														<div id="biz_vaidate_check" className="vaidate_check flexBox" style={{display:"flex"}}>유효하지 않은 사업자등록번호 입니다.</div>	
													</>
													: this.state.biz_txt_case == "error_case_2"
													? <>
														<div id="biz_vaidate_check" className="vaidate_check flexBox" style={{display:"flex"}}>유효하지 않은 사업자등록번호 입니다.</div>
													</> : ""
												}
											</div>
										}
										<div className="form-group m-b-md">
											<label>호스팅</label>
											<div className="input-group">
												<input type="text" id="hosting_type" className="join-form form-control" placeholder="예) 카페24, 메이크샵, 자사몰 등"
													value={this.state.hosting_type}
													onChange={(e) => this.handleText(e, 'hosting_type')}
													onBlur={this.handleHostingTypeOnBlur}
													required maxLength="200" />
											</div>
											{/* <h5 style={{color:'red'}}>* 비밀번호 분실시 카카오 알림톡을 수신 가능한 연락처</h5> */}
										</div>
										{/* <div className="form-group m-b-md">
											<label>재직자 수</label><small className="text-navy"> *</small>
											<div className="input-group">
												<input type="number" id="emp_cnt" className="join-form form-control"
													value={this.state.emp_cnt} 
													onKeyDown={this.number_keydown}
													onChange={(e) => this.handleText(e, 'emp_cnt')} 
													required />
											</div>
										</div> */}
										{/* {this.telValid()}
										{this.emailValid()} */}
									</>
								}
							</>
						: 	""
					}

					{
						this.props.step == 4
						?
							<>
								<div style={{fontSize:"15px"}}>
									{
										this.state.manager_yn == null || this.state.manager_yn != 'Y'
										?	<>
												<div style={{textAlign:"left"}}>
												대표자님 외에 로보MD를 주로 이용하실 담당자분이 따로 계시나요?
												</div>
												<div className="redio_v2 flexBox" style={{marginTop: "auto", marginTop:"10px", marginBottom: "40px", flexDirection:"row", whiteSpace:"nowrap"}}>
													<div className="flexBox radio_area">
														<input type="radio"></input>
														{
															this.state.manager_yn != null && this.state.manager_yn == "Y"
															?
																<>
																<div className="radio on" onClick={
																	e => this.onClickManagerYn(e, "Y")
																}><div className="hover"></div></div>
																</>
															:	
																<>
																<div className="radio" onClick={
																	e => this.onClickManagerYn(e, "Y")
																}><div className="hover"></div></div>
																</>
														}
														<div className="radio_text" style={{fontSize:"15px"}}>네</div>
													</div> 
													{/* <div className="flexBox radio_area" style={{marginLeft:"18px"}}> */}
													<div className="radio_area" style={{marginLeft:"18px", display:"flex"}}>
														<input type="radio"></input>
														{
															this.state.manager_yn != null && this.state.manager_yn == "N"
															?
																<>
																<div className="radio on" onClick={
																	e => this.onClickManagerYn(e, "N")
																}><div className="hover"></div></div>
																</>
															: 	
																<>
																<div className="radio" onClick={
																	e => this.onClickManagerYn(e, "N")
																}><div className="hover"></div></div>	
																</>
														}
														<div className="radio_text" style={{fontSize:"15px"}}>아니요</div>
													</div> 
												</div>
											</>	
										:	""
									}
								</div>
								{
									this.state.manager_yn != null && this.state.manager_yn == "Y"
									?
										<>
											<div className="form-group m-t-32">
												{/* <h3 style={{padding:"20px 0 5px 0", color:"#10CFC9"}}>회사 부가 정보 (선택사항)</h3> */}
												<div style={{fontSize:"15px", marginBottom:"20px", fontWeight:"normal"}}>로보MD를 주로 이용하실 업무 담당자분이 따로 계시는 경우 입력해 주세요. <b>회사 정보와 동일하다면 입력하지 않으셔도 됩니다.</b></div>
											</div>
											{/* ******************************************트렌드 리포트 가입 신청서****************************************** */}
											{/* <SignupForm_trend user_join_cls={this.props.user_join_cls} 
															userState={this.state}
															handleText={this.handleText}/> */}
											{/* ******************************************연동 플랫폼별 회원가입 폼****************************************** */}
											{/* ******************************************Cafe24 업체 연동 모듈****************************************** */}
											{/* <SignupForm_cafe24 user_join_cls={this.props.user_join_cls} 
															token={this.state.token} 
															storeState={this.state}
															handleText={this.handleText}
															getStore={this.getStore}
															mall_id={this.state.mall_id}
															fromMyApp={this.props.fromMyApp}
														/> */}
											{/* ****************************************** 가입신청완료****************************************** */}
											{/* <button onClick={this.onClickSignupButton} className="btn btn-primary block full-width m-b">가입신청</button>&nbsp; */}
										</>
									:
										""
								}
							</>
						:	""
					}
					{
						this.props.step < 3 && this.props.step != 2
						?
							<Button
								className={["btn-auto-b", this.state.btn_next && "cursor-pointer-important"].join(' ')}
								onClick={this.onClickNextStep}
								disabled={!this.state.btn_next}
								width={"100%"}
								height={"50px"}
								fontSize={"16px"}
							>
								{t('regist.next_step_button')}
							</Button>
						:	this.props.step >= 3
							?
								<Button
									className={["btn-auto-b", this.state.btn_pass_yn == "Y" && "cursor-pointer-important"].join(' ')}
									onClick={this.onClickSignupButton}
									disabled={this.state.btn_pass_yn == "N"}
									width={"100%"}
									height={"50px"}
									fontSize={"16px"}
								>
									{t('regist.regist_button')}
								</Button>
							: 	""
					}
                </div>
            
        )

        
        
    }
}

export default withTranslation()(SignupForm);