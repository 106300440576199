import React, { useState } from 'react';
import http from '../../modules/api';
import PopupFrame from '../../modules/popup/PopupFrame';
import styles from './RoboImgSettingPopup.module.css';
import ai_badge from '../../../resources/img/ai_badge.svg';
import arrows_clockwise from '../../../resources/img/arrows_clockwise.svg';
import arrows_clockwise_white from '../../../resources/img/arrows_clockwise_white.svg';
import SaveDialogV2 from '../../modules/dialogs/SaveDialogV2';
import * as StringUtil from '../../modules/utils/StringUtil';

const stability_prompt = {
    52000 : "style_type_list",
    53000 : "composition_type_list",
    54000 : "ratio_list",
};
const prompt_max_length = 1000; // 프롬프트 최대 길이

/**
 * @param {*} className 클래스 명
 * @param {*} eventSeq event_seq
 * @param {*} imgPosition 이미지 위치 번호
 * @param {*} bannerCd 배너 번호
 * @param {*} bannerDirection 배너 유형 (03000 or 07000)
 * @function onClose 팝업 닫기
 * @function sendRoboImg 로보이미지를 받는 함수
 */
class RoboImgSettingPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            prompt : "",
            before_prompt_seq : "",

            style_type_list : [],
            composition_type_list : [],
            ratio_list : [],

            style_type_cd : "",
            composition_type_cd : "",
            ratio : "",
            width : "",
            height : "",

            robo_img : "",

            show_input_prompt_popup : true, // 프롬프트를 입력하는 팝업 표시 상태
            show_loading_dialog : false, // 로딩 다이얼로그 표시 상태
            show_robo_img_popup : false, // 생성된 로보 이미지를 보는 팝업 표시 상태
        }
    }

    componentDidMount(){
        // 스타일, 구도, 비율 리스트 받아오기
        this.loadCommonCode(52000, (type) => this.setType(type, "style_type_cd"));
        this.loadCommonCode(53000, (type) => this.setType(type, "composition_type_cd"));
        this.loadCommonCode(54000, (type) => this.setTypeRatio(type));
    }

    /** cafe24.TB_CODE 값 조회 */
    loadCommonCode = (upper_code_cd, setType) => {
		http.get("/rest/common/cafe24Code?upper_code_cd="+upper_code_cd)
		.then(res => {
            if(res.data){
                this.setState({
                    [stability_prompt[upper_code_cd]]:res.data,
                });
                setType(res.data[0]);
            }
        });
    }

    /** 각 리스트의 선택된 code_cd 값을 적용 */
    setType = (type, name) => {
        this.setState({[name] : type.code_cd});
    }

    /** 선택된 비율 값을 적용 */
    setTypeRatio = (type) => {
        const [width, height] = type.val1.split('*');
        this.setState({
            ratio : type.val1,
            width : width,
            height : height,
        });
    }

    /** 프롬프트 입력 */
    onChangePrompt = (e) => {
        const value = e.target.value.substring(0, prompt_max_length);
        this.setState({prompt : value});
    }

    /** 체험해보기 클릭 : prompt 값 랜덤으로 받기 */
    getRandomPrompt = () => {
		http.get("/rest/common/sampple/prompts?prompt_seq="+this.state.before_prompt_seq)
		.then(res => {
            if(res.data){
                this.setState({
                    prompt: res.data.sample_prompts.substring(0, prompt_max_length),
                    before_prompt_seq: res.data.sample_prompt_seq,
                });
            }
        });
    }

    /** 이미지 만들기 */
    getRoboImage = () => {
        const prompt = this.state.prompt.replaceAll(/[\[\]:]/g, ""); // 프롬프트의 대괄호와 콜론을 빈문자로 바꿔서 전달
        this.setState({prompt : prompt});
        
        if(StringUtil.checkEmptyString(prompt)){
            alert("입력하신 내용이 없습니다. 아이디어를 입력해 주세요.");
            this.setState({prompt : ""});
            return false;
        }

        this.setState({
            show_input_prompt_popup : false,
            show_robo_img_popup : false,
            show_loading_dialog : true,
        });
        const sendForm = {
            prompt : prompt,
            style_type_cd : this.state.style_type_cd,
            composition_type_cd : this.state.composition_type_cd,
            width : this.state.width,
            height : this.state.height,
        };
		http.get("/rest/common/stableDiffusion/stability", {params:sendForm})
		.then(res => {
            if(res.data.code == "200" && res.data.result.resCode != "500"){
                this.setState({
                    robo_img:res.data.result.url[0],
                    show_loading_dialog : false,
                    show_robo_img_popup : true,
                });
                this.saveRoboImageHist(res.data.final_prompts, res.data.result.url);
            } else{
                this.saveRoboImageHist(res.data.final_prompts, res.data.result.url);
                alert("이미지를 생성할 수 없어요.\n다시 시도해도 안되면 다른 아이디어를 입력해 주세요.");
                this.setState({
                    show_loading_dialog : false,
                    show_input_prompt_popup : true,
                });
            }
        })
        .catch(error=> {
            alert("이미지를 생성할 수 없어요.\n다시 시도해도 안되면 다른 아이디어를 입력해 주세요.");
            this.setState({
                show_loading_dialog : false,
                show_input_prompt_popup : true,
            });
        });
    }

    saveRoboImageHist = (final_prompts, url) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };
        const body = {
            event_seq: this.props.eventSeq,
            img_position: this.props.imgPosition,
            banner_cd: this.props.bannerCd,
            banner_direction: this.props.bannerDirection,
            kor_prompts: this.state.prompt,
            final_prompts: final_prompts,
            style_type_cd: this.state.style_type_cd,
            composition_type_cd: this.state.composition_type_cd,
            res_img: url,
        };
        http.post("/rest/common/insert/stability/call/hist", body, config);
    }

    render () {
		const {className, onClose, sendRoboImg} = this.props;
        return (
            <>
                <div className={["robo_img_setting_popup",className,styles.robo_img_setting_popup].join(' ')}>
                    {this.state.show_input_prompt_popup &&
                        <PopupFrame
                            maxWidth={"460px"}
                            title={"로보이미지"}
                            titleIcon={ai_badge}
                            content={"원하는 이미지를 AI로 만들어낼 수 있어요.<br/>아이디어만 입력해서 AI로 간단하게 완성해 보세요!"}
                            onClose={onClose}
                        >
                            <div className={styles.robo_img_popup}>
                                <div className={styles.input_prompt}>
                                    <textarea
                                        placeholder={"아이디어를 입력해 주세요.\n상품, 특징, 위치, 환경, 주변 소품 등을 포함하여\n자세하게 설명할수록 멋진 이미지가 나와요 :)"}
                                        value={this.state.prompt}
                                        onChange={e => this.onChangePrompt(e)}
                                        maxLength={prompt_max_length}
                                    ></textarea>
                                    <div className={"style_guide_v2"}>
                                        {this.state.before_prompt_seq
                                        ?  <button className={["hover_dark",styles.new_prompt_btn].join(' ')} onClick={this.getRandomPrompt}>
                                            <img src={arrows_clockwise_white}/>
                                            새로운 아이디어
                                        </button>
                                        :<button className={["hover_dark",styles.hover_dark].join(' ')} onClick={this.getRandomPrompt}>아이디어 추천받기</button>}
                                    </div>
                                </div>
                                <div className={styles.input_prompt_info}>{"대괄호([ ])와 콜론(:)은 입력할 수 없어요."}</div>

                                <div className={styles.content_title} style={{marginTop:"40px"}}>스타일</div>
                                <div className={styles.content_area}>
                                    {this.state.style_type_list.map((obj) => (
                                        <div
                                            className={[styles.content, obj.code_cd == this.state.style_type_cd ? styles.select_content : ''].join(' ')}
                                            onClick={() => this.setType(obj, "style_type_cd")}
                                        >
                                            <label>{obj.code_name}</label>
                                            {obj.code_cd == this.state.style_type_cd
                                            ?<div className={styles.img_area} style={{background: "linear-gradient(0deg, rgba(0, 226, 230, 0.2), rgba(0, 226, 230, 0.2)), linear-gradient(180deg, rgba(0, 0, 0, 0) 66.15%, rgba(0, 0, 0, 0.6) 100%), url("+obj.val2+")"}}></div>
                                            :<div className={styles.img_area} style={{background: "linear-gradient(180deg, rgba(0, 0, 0, 0) 66.15%, rgba(0, 0, 0, 0.6) 100%), url("+obj.val2+")"}}></div>}
                                        </div>
                                    ))}
                                </div>

                                <div className={styles.content_title}>구도</div>
                                <div className={styles.content_area}>
                                    {this.state.composition_type_list.map((obj) => (
                                        <div
                                            className={[styles.content, obj.code_cd == this.state.composition_type_cd ? styles.select_content : ''].join(' ')}
                                            onClick={() => this.setType(obj, "composition_type_cd")}
                                        >
                                            <label>{obj.code_name}</label>
                                            {obj.code_cd == this.state.composition_type_cd
                                            ?<div className={styles.img_area} style={{background: "linear-gradient(0deg, rgba(0, 226, 230, 0.2), rgba(0, 226, 230, 0.2)), linear-gradient(180deg, rgba(0, 0, 0, 0) 66.15%, rgba(0, 0, 0, 0.6) 100%), url("+obj.val2+")"}}></div>
                                            :<div className={styles.img_area} style={{background: "linear-gradient(180deg, rgba(0, 0, 0, 0) 66.15%, rgba(0, 0, 0, 0.6) 100%), url("+obj.val2+")"}}></div>}
                                        </div>
                                    ))}
                                </div>

                                <div className={styles.content_title}>비율</div>
                                <div className={styles.content_area}>
                                    {this.state.ratio_list.map((obj) => (
                                        <div
                                            className={[styles.content, styles.ratio_content, obj.val1 == this.state.ratio ? styles.select_content : ''].join(' ')}
                                            onClick={() => this.setTypeRatio(obj)}
                                        >
                                            <label className={styles.color_gray}>{obj.code_name}</label>
                                            {obj.val1 == this.state.ratio
                                            ?<div className={styles.img_area} style={{background: "linear-gradient(0deg, rgba(0, 226, 230, 0.15), rgba(0, 226, 230, 0.15)), url("+obj.val2+")"}}></div>
                                            :<div className={styles.img_area} style={{background: "url("+obj.val2+")"}}></div>}
                                        </div>
                                    ))}
                                </div>
                                
                                <div className={["style_guide_v2",styles.make_btn].join(' ')}>
                                    {this.state.prompt.length == 0
                                    ?<button className={["hover_mint", "disabled"].join(' ')} disabled={true}>이미지 만들기</button>
                                    :<button className={"hover_mint"} onClick={this.getRoboImage}>이미지 만들기</button>}
                                </div>
                            </div>
                        </PopupFrame>
                    }

                    {this.state.show_loading_dialog &&
                        <SaveDialogV2
                            content={"요청하신 이미지를 만들고 있어요.<br/>조금만 기다려 주세요 :)"}
                            button_list={[{
                                title: "확인",
                                type: "1",
                                display_yn:"N",
                            }]}
                        />
                    }
                    
                    {this.state.show_robo_img_popup &&
                        <PopupFrame
                            maxWidth={"600px"}
                            onClose={onClose}
                            headerMarginBottom={"0px"}
                            backAction={() => {
                                this.setState({
                                    show_input_prompt_popup : true,
                                    show_robo_img_popup : false,
                                });
                            }}
                        >
                            <div className={styles.img_popup_title}>
                                AI 이미지가 만들어졌어요.<br/>
                                나만의 상품 이미지로 배너 디자인에 활용해 보세요.
                            </div>
                            <img className={styles.robo_img} src={this.state.robo_img}/>
                            <div className={[styles.robo_img_button_area, "style_guide_v2"].join(' ')}>
                                <button className={"hover_white"} onClick={this.getRoboImage}>다른 이미지 보기</button>
                                <button className={"hover_mint"} onClick={() => sendRoboImg(this.state.robo_img)}>이미지 적용하기</button>
                            </div>
                        </PopupFrame>
                    }
                </div>
            </>
        )
    };
}

RoboImgSettingPopup.defaultProps = {
    className : "",
    sendRoboImg : "",
    eventSeq : "-1",
    imgPosition : "-1",
    bannerCd : "-1",
    bannerDirection : "-1"
}

export default RoboImgSettingPopup;